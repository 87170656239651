<ng-container *ngIf="address">
  <p-dialog
    [header]="formattedAddress"
    [(visible)]="display"
    [style]="{ width: '50vw' }"
  >
    <tj-address-map *ngIf="display" [address]="address"></tj-address-map>
  </p-dialog>

  <div class="flex">
    <div>
      <button pButton icon="pi pi-external-link" (click)="openMap()" class="mr-2 p-button-text "></button>
    </div>
    <div>
      <button
        pButton
        pRipple
        type="button"
        (click)="onClick()"
        icon="pi pi-map-marker"
        class="w-auto ml-0 pr-1 p-button-rounded p-button-text"
        iconPos="center"
      ></button>
    </div>
    <div *ngIf="showAddress" class="align-self-center flex line-height-2">
      {{ address | address }}
    </div>
    <button *ngIf="showAddress" pButton pRipple type="button" icon="pi pi-copy"
            class="ml-2 p-button-rounded p-button-text"
            [cdkCopyToClipboard]="(address ? (address | address) : '')"
            (cdkCopyToClipboardCopied)="onCopySuccess($event)"></button>


  </div>
</ng-container>
