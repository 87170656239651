import {Injectable} from '@angular/core';
import {RouteItemModel, RouteRequestModel} from '../../api/model/route/route.request.model';
import { AddressedMarker } from './components/routes/addressed-marker.model';
import {TechnicianMinimalListItem} from '../../api/model/TechnicianMinimalListItem';
import {NotificationService} from '../../core/services';
import {AddressDetails} from '../../api/model/Address.model';
import {ApiService} from '../../api/service/api.service';
import {Router} from '@angular/router';
import {TechRoutes, TicketRouteModel} from '../../api/model/ticket/ticket.route.details.model';
import {Area} from '../../api/model/Area.model';
import {StopItemModel} from '../../api/model/route/route.line.model';

@Injectable()
export class RouteService {

  constructor(private api: ApiService, private notificationService: NotificationService,
              private router: Router) {
  }

  save(id: number,
       date: Date,
       email: string,
       addressedMarkers: AddressedMarker[],
       startMarker: AddressedMarker,
       endMarker: AddressedMarker,
       roundTrip: boolean,
       legs: google.maps.DirectionsLeg[] = [],
       technician: TechnicianMinimalListItem,
       notify): void {

    const startStopOrder = 0;
    const start = this.mapMarkerToRouteItemModel(startMarker.id, startStopOrder, startMarker.customer, startMarker.address, 0, null, false, false);

    const routes: RouteItemModel[] = addressedMarkers
      .filter(it => it.address.addressFormatted !== undefined)
      .map((it, index) => this.mapMarkerToRouteItemModel(it.id, index + 1, it.customer, it.address, it.timeNeeded, it.ticketId, it.asStart, it.asEnd));

    const endStopOrder = routes.length + 1;
    const end = this.mapMarkerToRouteItemModel(endMarker.id, endStopOrder, endMarker.customer, endMarker.address, 0, null, false, false);


    routes.forEach((route, index) => {
      route.distance = legs[index].distance.value;
      route.duration = legs[index].duration.value;
    });
    end.distance = legs[legs.length - 1].distance.value;
    end.duration = legs[legs.length - 1].duration.value;

    const requestModel: RouteRequestModel = new RouteRequestModel();
    requestModel.id = id;
    requestModel.email = email;
    requestModel.date = date;
    requestModel.technicianId = technician?.id;
    requestModel.routes = routes;
    requestModel.startRoute = start;
    requestModel.endRoute = end;
    requestModel.roundTrip = roundTrip;
    if (requestModel.id) {
      this.update(requestModel, notify);
    } else {
      this.create(requestModel, notify);
    }
  }

  private create(requestModel: RouteRequestModel, notify: boolean) {
    this.api.routeApi.create(requestModel, notify)
      .subscribe((response) => {
        const message = notify ? 'The route has been saved and sent' : 'The route has been saved';
        this.router.navigate(['/routes', response.id]);
        this.notificationService.success(message);
      }, error => {
        this.notificationService.error('Something went wrong: ', error);
      });
  }

  private update(requestModel: RouteRequestModel, notify: boolean) {
    this.api.routeApi.update(requestModel, notify)
      .subscribe((response) => {
        const message = notify ? 'The route has been saved and sent' : 'The route has been saved';
        this.notificationService.success(message);
      }, error => {
        this.notificationService.error('Something went wrong: ', error);
      });
  }

  private mapMarkerToRouteItemModel(id: number, order: number, name: string, address: AddressDetails, timeNeeded: number, ticketId: number, asStart: boolean, asEnd: boolean) {
    const item: RouteItemModel = new RouteItemModel();
    item.id = id;
    item.order = order;
    item.name = name;
    item.address = address;
    item.addressUrl = 'https://maps.google.com/maps?q=' + address.addressFormatted;
    item.timeNeeded = timeNeeded || 0;
    item.ticketId = ticketId;
    item.asStart = asStart;
    item.asEnd = asEnd;
    return item;
  }


  public createMapOfTechAndSameDayRoutesWithMatchingArea(technicianRoutes: TechRoutes[] | undefined, areas: Area[] | undefined): Map<number, TechRoutes> {
    const techRoutesByDateMap = this.groupById(technicianRoutes || []);
    const customerAddressAreas = areas?.map(area => area.code) || [];
    for (const techRouteByDate of techRoutesByDateMap.values()) {
      for (const routeByDate of techRouteByDate.routesByDate) {
        const stopAreaCodes = this.getStopAreaCodes(routeByDate.stops);
        routeByDate.matchByArea = stopAreaCodes.find(areaCode => customerAddressAreas.includes(areaCode));
      }
      const byMatchingArea = (a, b) => (b.matchByArea ? 1 : -1) - (a.matchByArea ? 1 : -1);
      techRouteByDate.routesByDate.sort(byMatchingArea);
    }
    return techRoutesByDateMap;
  }

  private getStopAreaCodes(stops: StopItemModel[]): string[] {
    const stopArr = stops
      .flatMap(stop => stop.address?.areas || [])
      .filter(area => !!area)
      .map(area => area.code);
    return [...new Set(stopArr)];
  }

  private groupById(technicianRoutes: TechRoutes[]): Map<number, TechRoutes> {
    return technicianRoutes.reduce((acc, route) => {
      acc.set(route.id, route);
      return acc;
    }, new Map<number, TechRoutes>());
  }

  public createMapOfTechAndLatestRoute(routes: TicketRouteModel[]) {
    return routes.reduce((acc, route) => {
      const techId = route.technicianId;
      if (!acc.has(techId) || acc.get(techId).routeId < route.routeId) {
        acc.set(techId, route);
      }
      return acc;
    }, new Map<number, TicketRouteModel>());
  }
}
