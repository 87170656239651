import {
  AreaApi,
  ChatApi,
  CommentApi,
  GalleryApi,
  InvoiceApi,
  PartnerApi,
  PayFormApi,
  SignatureApi,
  PaymentApi,
  SchedulerApi,
  TagApi,
  TechnicianApi,
  TicketApi,
  UserApi,
  ZipCodeApi
} from '../endpoints';
import {Injectable} from '@angular/core';
import {GlobalConfigApi} from '../endpoints/global-config.api';
import {RouteApi} from '../endpoints/route.api';
import {GeocodingApi} from '../endpoints/geocoding.api';

@Injectable()
export class ApiService {
  constructor(
    public invoice: InvoiceApi,
    public payForm: PayFormApi,
    public ticket: TicketApi,
    public partner: PartnerApi,
    public technician: TechnicianApi,
    public gallery: GalleryApi,
    public signatureApi: SignatureApi,
    public geocodingApi: GeocodingApi,
    public chat: ChatApi,
    public payment: PaymentApi,
    public user: UserApi,
    public zipCode: ZipCodeApi,
    public comment: CommentApi,
    public globalConfig: GlobalConfigApi,
    public routeApi: RouteApi,
    public area: AreaApi,
    public scheduler: SchedulerApi,
    public tag: TagApi
  ) {
  }
}
