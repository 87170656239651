<ng-container *ngIf="ticket">
  <div>
    <div>
      <tj-ticket-details
        [ticketId]="ticket.id"
        [ticket]="ticket"
        [partner]="partner"
        [customer]="customer"
        [ticketForm]="ticketForm"
        [mainCustomerContactPerson]="mainCustomerContactPerson"
        [mainPartnerContactPerson]="mainPartnerContactPerson"
        [isMobile]="isMobile"
        [readonly]="readonly"
      ></tj-ticket-details>
    </div>


    <p-panel header="Attachments" [toggleable]="true" styleClass="mb-4 col-12 lg:col-offset-3 lg:col-6">
      <div class="col-12" *tjHasRole="'TICKET_ADD_ATTACHMENTS'">
        <div class="file-upload-container">
          <p-fileUpload
            #uploader
            [disabled]="isUploading"
            (uploadHandler)="uploadHandler($event.files, uploader)"
            [auto]="true"
            [customUpload]="true"
            [mode]="'basic'"
            [multiple]="true"
            chooseLabel="Browse"
          >
          </p-fileUpload>
          <ng-container *ngIf="isFileShowing$ | async">
            <div class="upload-overlay">
              <i class="pi pi-spinner pi-spin"></i>
            </div>
          </ng-container>
        </div>
        
        
      </div>
      <tj-gallery-viewer
        *ngIf="ticket && ticket.galleryId"
        [galleryId]="ticket.galleryId"
        [ticket]="ticket"
      ></tj-gallery-viewer>

    </p-panel>
  </div>

  <tj-image-edit [galleryId]="newGalleryId"></tj-image-edit>
  <p-confirmDialog appendTo="body" key="confirmDeactivation"></p-confirmDialog>

  <p-confirmDialog appendTo="body" [rejectVisible]="false" acceptLabel="Reload" key="confirmUpdatedTicket"></p-confirmDialog>

  <p-confirmDialog appendTo="body" [rejectVisible]="false" acceptLabel="Ok" key="confirmUnsavedChanges"></p-confirmDialog>
</ng-container>
