
import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/service/api.service';
import { ConfigService } from '../../../core/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ClientSignture } from '../../../api/model/ticket/client-signature';
import { Ticket } from '../../../api/model/Ticket.model';
import { TjRxStompService } from '../../../main/shared/services/tj-rx-stomp.service';
import { Subscription } from 'rxjs';
import { Message } from '@stomp/stompjs';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../core/services/user.service';
import { User } from '../../../api/model/User.model';

@Injectable()
export class TicketUpdateCheckService {

  private readonly UPDATE_INTERVAL: number = 15000;

  private signature$: Subject<ClientSignture> = new Subject<ClientSignture>();

  private ticketChanged$: Subject<boolean> = new Subject<boolean>();

  private readonly ticketSignature: Observable<ClientSignture> = this.signature$.asObservable();

  private readonly ticketChanged: Observable<boolean> = this.ticketChanged$.asObservable();

  private timeInterval: ReturnType<typeof setInterval>;

  private latestSignature: ClientSignture;

  private mySignature: ClientSignture;

  private topicSubscription: Subscription;

  constructor(private api: ApiService,
    private configService: ConfigService,
    private rxStompService: TjRxStompService,
    private userService: UserService) {
  }

  public initTicketUpdateTracking(ticket: Ticket) {
    const trackType = environment.ticketSignatureTrackType;
    this.mySignature = this.configService.getClientSignature();
    this.latestSignature = this.mySignature;
    this.ticketSignature.subscribe((actualTicketSignature: ClientSignture) => {

      // set initial signature
      if (!this.latestSignature) {
        this.latestSignature = actualTicketSignature;
        return;
      }

      // Was the ticket changed?
      if (!actualTicketSignature.isSame(this.latestSignature)) {
        // Was the ticket changed by the same client?
        if (!this.mySignature.isSameClient(actualTicketSignature)) {
          this.latestSignature = actualTicketSignature;
          this.ticketChanged$.next(true);
        }
      }
    });
    if(trackType === 'WS') {
      this.userService.fetchCurrentUser().subscribe((user: User) => {
          this.topicSubscription = this.rxStompService
                .watch('/topic/' + user.realm + '/' + environment.ticketSignatureWSPath +'/' + ticket.id)
                .subscribe((message: Message) => {
                  const value = ClientSignture.buildFromBase64String(JSON.parse(message.body).signature);
                  this.signature$.next(value);
          });
      });
    }
    else if(trackType === 'API') {
      this.timeInterval = setInterval(() => {
          this.loadLastTicketSignature(ticket.id);
      }, this.UPDATE_INTERVAL);
    }
    else {
      console.log('Unknown Ticket Signature Track Type ' + trackType);
    }

    return this.ticketChanged;
  }

  private loadLastTicketSignature(id: number): void {
    this.api.ticket.getLastSignature(id)
      .subscribe((value: ClientSignture) => {
        this.signature$.next(value);
      }, (err) => {
        console.log('Cannot load ticket signature. Checks will be cancelled.', err);
        clearInterval(this.timeInterval);
      });
  }

  destroy() {
    if(this.timeInterval) {
      clearInterval(this.timeInterval);
    }
    if(this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
  }
}
