import { Injectable } from '@angular/core';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root',
})
export class ImageCompressionService {
  async compressImage(file: File): Promise<File> {
    const options = {
      maxSizeMB: 1.5, // Set target size to around 1 MB
      maxWidthOrHeight: 1024, // Optional: Set max dimensions
      useWebWorker: true, // Use Web Worker for performance
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression failed:', error);
      throw error;
    }
  }
}
