<ng-container *ngIf="displayedImages">
  <div *ngFor="let item of displayedImages; let i = index" class="image-container">
    <img [src]="getImagePath(item.cropPath, item.fileName, i)" alt="image-{{item.id}}"/>
  </div>
</ng-container>

<p-paginator [pageLinkSize]="4"
             [rowsPerPageOptions]="[5, 10 , 20, 30]"
             currentPageReportTemplate=" {first} to {last} of {totalRecords}"
             [showCurrentPageReport]="true"
             [rows]="rows"
             [totalRecords]="totalRecords"
             (onPageChange)="onPageChange($event)">
</p-paginator>
