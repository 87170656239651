import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ContactType} from '../../../shared/enums/contact.type';
import {ConfigService, NotificationService} from '../../../../core/services';
import {CustomerUpdateDetails} from '../../../../api/model/CustomerUpdateDetails.model';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Customer} from '../../../../api/model/Customer.model';

@Component({
  selector: 'tj-address-contact-dialog',
  templateUrl: './address-contact-dialog.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class AddressContactDialogComponent implements OnInit {
  @Input() mainContactPerson: ContactPerson;
  @Input() customer: Customer;
  @Input() readonly = false;
  @Output() onUpdateCustomerDetails = new EventEmitter<CustomerUpdateDetails>;
  actionForm: FormGroup;

  constructor(private fb: UntypedFormBuilder,
              public configService: ConfigService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.actionForm = this.fb.group({
      apt: this.customer.address.apt || '',
      additionalAddress: '',
      contacts: this.fb.array([])
    });
  }

  private generateContactControl(type: ContactType | null = null, value: string = '', id?: number) {
    return this.fb.group({
      id: id ? new UntypedFormControl(id) : null,
      type: new UntypedFormControl(type),
      value: this.fb.control(value, type === 'EMAIL' ? [Validators.email] : [Validators.required])
    });
  }

  addContact(type: ContactType | null = null, value: string = '', id?: number) {
    const contactGroup = this.generateContactControl(type, value, id);
    this.contacts.push(contactGroup);
  }

  removeContact(index: number): void {
    this.contacts.removeAt(index);
  }

  get contacts(): FormArray {
    return this.actionForm.get('contacts') as FormArray;
  }

  onSave(): void {
    if (this.readonly) {
      this.notificationService.warning('This ticket has been changed by another user! Reload the page.');
      return;
    }
    if (this.actionForm.valid) {
      const updateDetails = this.actionForm.getRawValue() as CustomerUpdateDetails;
      if (updateDetails.apt?.trim().length === 0 && updateDetails.additionalAddress?.trim().length === 0 && updateDetails.contacts.length === 0) {
        this.notificationService.warning('Updated form is empty');
        return;
      }

      updateDetails.mainContactPersonId = this.mainContactPerson.id;
      this.onUpdateCustomerDetails.emit(updateDetails);
    } else {
      console.error('Form is invalid:', this.actionForm.errors);
    }
  }

  getControl(controlName: string): UntypedFormControl {
    return this.actionForm.get(controlName) as UntypedFormControl;
  }

}
