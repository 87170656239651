import {Observable, of as observableOf, Subject} from 'rxjs';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {catchError, filter, map, mergeMap, take, tap} from 'rxjs/operators';
import {Ticket} from '../../../../api/model/Ticket.model';
import {ApiService} from '../../../../api/service/api.service';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {InvoiceService, TicketInvoiceWithTech} from '../../../invoice/service/invoice.service';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Messages} from '../../../../common/messages';
import {TicketPDFOptions} from '../../../../api/status/TicketPDFOptions';
import {download} from '../../../../api/model/shared/functions';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {Customer} from '../../../../api/model/Customer.model';
import {Partner} from '../../../../api/model/Partner.model';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {TicketPrintOptions} from '../../../../api/status/TicketPrintOptions';
import {TicketInvoice} from '../../../../api/model/TicketInvoice.model';
import {SentToPayformModel} from '../../../payform/models';
import {TicketDuplicateOptions} from '../../../../api/status/TicketDuplicateOptions';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {Tag} from '../../../../api/model/Tag.model';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ActionDetailsComponent} from '../../../shared/components';
import * as dayjs from 'dayjs';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

@Component({
  selector: 'tj-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit, OnChanges, OnDestroy {
  protected readonly TICKET_PARTNER_STATUS = TicketPartnerStatus;
  private routeChangeSubject = new Subject<boolean>();

  @Input() ticketId: number;

  @Input() ticket: Ticket;

  @Input() customer: Customer;

  @Input() partner: Partner;

  @Input() mainCustomerContactPerson: ContactPerson;

  @Input() mainPartnerContactPerson: ContactPerson;

  @Input() ticketForm: UntypedFormGroup;

  @Input() isMobile = false;

  @Input() readonly = false;

  isPayFormed = false;

  quickInvoiceForm: UntypedFormGroup;

  ticketInvoice: TicketInvoiceWithTech;

  status: typeof TicketStatus;

  ticketStatus: string | TicketStatus;

  originalTicketStatus: string | TicketStatus;

  pdfDownloadSpinner = false;

  selectedAction = null;

  editPartnerVisible = false;

  editCustomerVisible = false;

  isSaving = false;
  isSavingInvoice = false;

  sendToPayFormClicked = false;

  customEmailForm: UntypedFormGroup;

  customEmailDialog = false;
  notifyRouteDialog = false;
  notifyRouteChange = false;

  customerSignDialog = false;
  customerDetailsUpdateDialog = false;
  revisionLoaded = false;

  canConfirmCustomerAddress = !this.keycloakService.hasRole('TICKET_EDIT_CUSTOMER_ADDRESS_CONFIRM');

  readonly TicketStatus = TicketStatus;

  readonly TicketPdfOptions = TicketPDFOptions;

  readonly TicketPrintOptions = TicketPrintOptions;

  readonly TicketDuplicateOptions = TicketDuplicateOptions;
  isCustomerEmailMissing = false;

  pdfOptions = [
    {
      label: 'PDF with images',
      command: () => {
        this.onPdfAction(TicketPDFOptions.PDF);
      },
      items: this.pdfSendSubOptions(TicketPDFOptions.PDF)
        .filter(option => option.conditions())
    },
    {
      label: 'PDF without images',
      command: () => {
        this.onPdfAction(TicketPDFOptions.PDF_WITHOUT_IMAGES);
      },
      items: this.pdfSendSubOptions(TicketPDFOptions.PDF_WITHOUT_IMAGES)
        .filter(option => option.conditions())
    },
    {
      label: 'PDF (only images)',
      command: () => {
        this.onPdfAction(TicketPDFOptions.PDF_IMAGES_ONLY);
      },
      items: this.pdfSendSubOptions(TicketPDFOptions.PDF_IMAGES_ONLY)
        .filter(option => option.conditions())
    },
    {
      label: 'PDF without total amount',
      command: () => {
        this.onPdfAction(TicketPDFOptions.PDF_WITHOUT_TOTAL_AMOUNT);
      },
      items: this.pdfSendSubOptions(TicketPDFOptions.PDF_WITHOUT_TOTAL_AMOUNT)
        .filter(option => option.conditions())
    },
    {
      label: 'PDF with images without total amount',
      command: () => {
        this.onPdfAction(TicketPDFOptions.PDF_WITH_IMAGES_WITHOUT_TOTAL_AMOUNT);
      },
      items: this.pdfSendSubOptions(TicketPDFOptions.PDF_WITH_IMAGES_WITHOUT_TOTAL_AMOUNT)
        .filter(option => option.conditions())
    },
  ];

  printOptions = [
    {
      label: 'Print', command: () => {
        this.onPrintAction(TicketPrintOptions.PRINT);
      }
    },
    {
      label: 'Print without total amount', command: () => {
        this.onPrintAction(TicketPrintOptions.PRINT_WITHOUT_TOTAL_AMOUNT);
      }
    },
  ];


  saveOptionsMap = {
    'save': {
      label: 'Save',
      command: () => {
        this.onSave();
      },
      className: '',
      conditions: (): boolean => this.canSaveChanges(),
      disabled: false
    },
    'saveAndClose': {
      label: 'Save and Close',
      command: () => {
        this.onSaveAndClose();
      }, className: '',
      conditions: (): boolean => this.canSaveChanges(),
      disabled: false
    },
    'saveAsVerifiedAndClose': (disabledCondition) => {
      return {
        label: 'Save as verified / Close',
        command: () => {
          this.saveAsVerifyConditions();
        },
        className: '',
        conditions: (): boolean => this.canSaveChanges(),
        disabled: disabledCondition()
      };
    },
    'saveAsCompletedAndClose': () => {
      return {
        label: 'Save as completed / Close',
        command: () => {
          this.onSaveAsCompleted();
        },
        className: 'p-button-success',
        conditions: (): boolean => (this.showCompletedButton() && this.canSaveChanges()),
        disabled: false
      };
    }
  };

  saveOptions = [
    this.saveOptionsMap.saveAndClose
  ];

  primarySaveAction = this.saveOptionsMap.save;

  actionItems: MenuItem[] | undefined;


  duplicateOptions = [];

  areas: string[];

  mediaDialogRef: DynamicDialogRef;

  private previousUrl = false;
  filteredStatuses$: Observable<any[]>;

  constructor(
    public configService: ConfigService,
    private api: ApiService,
    private notificationService: NotificationService,
    private invoiceService: InvoiceService,
    private context: TicketEditContextService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private keycloakService: TjKeycloakService
  ) {
  }

  ngOnInit() {
    this.filteredStatuses$ = this.configService.ticketStatuses$.pipe(
      map(statuses => this.filterStatuses(statuses))
    );
    this.route.queryParams.subscribe(params => {
      if (params['ticketPreviousUrl'] === 'ticket-print') {
        this.previousUrl = true;
      }
    });
    this.isPayFormed = this.ticket.isSentToPayForm;
    this.quickInvoiceForm = this.fb.group({});
    this.originalTicketStatus = this.ticket.status;
    this.initiateDuplicateOptions();
    this.initSaveOptions();
    if (this.configService.isEnabledTerritory()) {
      this.areas = this.ticket.customer.address?.areas?.map(it => it.code);
    }

    this.actionItems = [
      {
        label: 'Print',
        items: this.printOptions,
      },
      {
        label: 'Pdf',
        items: this.pdfOptions,
      },
      {
        label: 'Duplicate',
        items: this.duplicateOptions,
      },
      {
        label: 'Pay',
        command: () => {
          this.onPay();
        }
      }
    ];

    if (this.keycloakService.hasRole('TICKET_DELETE')) {
      this.actionItems.push({
        label: 'Delete',
        command: () => {
          this.onDeleteTicket();
        }
      });
    }
  }

  private pdfSendSubOptions(option: TicketPDFOptions) {
    return [
      {
        label: 'Send to Account/3rd Party',
        command: () => {
           this.sendToPartner(option);
        },
        conditions: (): boolean => this.keycloakService.hasRole('TICKET_PDF_SEND_PARTNER')
      },
      {
        label: 'Send to customer',
        command: () => {
          if(this.ticket.ticketPartnerStatus === TicketPartnerStatus.PRIVATE_BUSINESS
            || this.ticket.ticketPartnerStatus === TicketPartnerStatus.REGISTERED
          )  {
          this.confirmationService.confirm({
            message: ' Send to end customer? ',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'pdf_to_customer_confirmation_dialog',

            accept: () => {
              this.sendToCustomer(option);
            }
          })} else 
          this.sendToCustomer(option);

        },
        conditions: (): boolean => this.keycloakService.hasRole('TICKET_PDF_SEND_CUSTOMER')
      },
      
      {
        label: 'Send to email',
        command: () => {
          this.openCustomEmailDialog(option);
        },
        conditions: (): boolean => this.keycloakService.hasRole('TICKET_PDF_SEND_EMAIL')
      }
    ];
  }

  showCompletedButton(): boolean {
    return (this.ticketForm.controls['technicians'] as FormArray)?.length > 0;
  }

  initSaveOptions() {
    if (this.loggedInUserIsHeadTech(this.ticket)) {
      const saveOption = this.saveOptionsMap.saveAsCompletedAndClose();
      this.saveOptions.push(saveOption);
    }

    if (this.keycloakService.hasRole('TICKET_EDIT_STATUS_ALL')) {
      const disabledCondition = () => this.ticket.status !== TicketStatus.COMPLETED || !!this.ticket.invoiceId;
      const saveOption = this.saveOptionsMap.saveAsVerifiedAndClose(disabledCondition);
      // If the ticket is sent to payform and combined invoice is enabled, the primary save action is Save and Close
      if (this.ticket.isSentToPayForm && this.ticket.partner && this.ticket.partner.combinedInvoice) {
        // Add "Save" action to the save options
        this.saveOptions.push({...this.primarySaveAction});
        this.primarySaveAction = saveOption;
      } else {
        this.saveOptions.push(saveOption);
      }
      if (this.ticket.invoiceId) {
        this.primarySaveAction = this.saveOptionsMap.saveAndClose;
        const number = this.saveOptions.findIndex(action => action.label === 'Save and Close');
        this.saveOptions[number] = this.saveOptionsMap.save;
      }
    }
  }

  public canUpdateCustomerAddressAndAddContacts(): boolean {
    return this.loggedInUserIsHeadTech(this.ticket) &&
      this.keycloakService.hasRole('TICKET_EDIT_CUSTOMER_ADDRESS_UNIT') &&
      this.keycloakService.hasRole('TICKET_EDIT_CUSTOMER_ADD_CONTACTS');
  }

  public loggedInUserIsHeadTech(ticket: Ticket): boolean {
    return ticket.technicians.some(technician => {
      return technician.username === this.keycloakService.user.username && technician.head;
    });
  }

  canSaveChanges(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_SAVE');
  }

  initiateDuplicateOptions() {
    if (this.keycloakService.hasRole('TICKET_DUPLICATE')) {
      this.duplicateOptions.push({
        label: 'Create duplicate', command: () => {
          this.onDuplicateAction(TicketDuplicateOptions.DUPLICATE);
        }
      });
    }

    if (this.keycloakService.hasRole('TICKET_DUPLICATE_PRIVATE')) {
      this.duplicateOptions.push({
        label: 'Create private duplicate', command: () => {
          this.onDuplicateAction(TicketDuplicateOptions.DUPLICATE_PRIVATE);
        }
      });
    }
  }

  getControlForGroup(formGroup: UntypedFormGroup, fcName: string): UntypedFormControl {
    return formGroup.get(fcName) as UntypedFormControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes?.ticket?.previousValue !== this.ticket) ||
      this.ticketId !== changes?.ticketId?.previousValue
    ) {
      if (this.ticket.id) {
        this.calculateTicketInvoice();
        if (!this.ticket.technicians || this.ticket.technicians.length === 0) {
          this.ticket.technicians = this.ticket.technicians || [];
        }
      }
    }
  }

  async onSave() {
    try {
      // Check for PayForm changes
      const isPayFormResolved = await this.confirmPayFormChanges()
        .then(() => true)
        .catch(() => false);

      if (!isPayFormResolved) {
        return;
      }

      // Check for Invoice changes only if PayForm was resolved
      const isInvoiceResolved = await this.confirmInvoiceChanges()
        .then(() => true)
        .catch(() => false);

      if (isInvoiceResolved) {
        // If Invoice change is resolved, update invoice and optionally save ticket
        this.updateInvoice(this.ticketId, true, true);
      } else if (!this.isInvoiceChange()) {
        // If Invoice is not changed and PayForm is resolved, save ticket
        this.saveTicket().subscribe(() => this.refreshPage());
      }
    } catch (e) {
      console.error('Error during save process', e);
    }
  }

  async onSaveAndClose() {
    try {
      // Check PayForm changes
      const isPayFormResolved = await this.confirmPayFormChanges()
        .then(() => true)
        .catch(() => false);

      if (!isPayFormResolved) {
        return;
      }

      // Check Invoice changes
      const isInvoiceResolved = await this.confirmInvoiceChanges()
        .then(() => true)
        .catch(() => false);

      // Do nothing if the invoice has unsaved changes, and the user rejects the update prompt.
      if (!isInvoiceResolved && this.isInvoiceChange()) {
        return;
      }

      // Check if the technician is forgotten
      const isTechnicianResolved = await this.confirmForgotTechnician()
        .then(() => true)
        .catch(() => false);

      if (isTechnicianResolved) {
        if (isInvoiceResolved) {
          // Save the ticket and Update invoice
          this.updateInvoice(this.ticketId, true, false, true);
        } else {
          // Save the ticket and navigate back
          this.saveTicket().subscribe(() => window.history.back());
        }
      }
    } catch (e) {
      console.error('Error during save and close process', e);
    }
  }


  async onSaveAndNotify() {
    if (this.checkReadonly()) {
      return;
    }
    try {
      // Check PayForm changes
      const isPayFormResolved = await this.confirmPayFormChanges()
        .then(() => true)
        .catch(() => false);

      if (!isPayFormResolved) {
        return;
      }

      // Check Invoice changes
      const isInvoiceResolved = await this.confirmInvoiceChanges()
        .then(() => true)
        .catch(() => false);

      if (!isInvoiceResolved && this.isInvoiceChange()) {
        return;
      }

      if (isInvoiceResolved) {
        // Save the ticket and Update invoice
        this.updateInvoice(this.ticketId, true, true, false);
      } else {
        // Save the ticket and notify the customer
        this.saveTicket(true).subscribe(() => {
          this.notificationService.success(Messages.TICKET_SAVED_CUSTOMER_NOTIFIED);
          this.refreshPage();
        });
      }
    } catch (e) {
      console.error('Error during save and notify process', e);
    }
  }

  saveAsVerifyConditions() {
    if (!this.ticket.isSentToPayForm || this.ticket.invoiceAmount === 0) {
      this.onSaveAsVerifyWarning();
    } else {
      this.onSaveAsVerified();
    }
  }

  onSaveAsVerified() {
    this.confirmPayFormChanges().then(() => {
      this.saveTicket(false, TicketStatus.VERIFIED)
        .subscribe(() => window.history.back());
    });
  }

  private onSaveAsVerifyWarning(): void {
    this.confirmSaveAndVerify()
      .then(() => this.onSaveAsVerified())
      .catch(reason => {});
  }

  onSaveAsCompleted() {
    this.saveTicket(false, TicketStatus.COMPLETED)
      .subscribe(() => window.history.back());
  }

  showDuplicationReasonDialog() {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Ticket duplication reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }));
  }

  showReschedulingReasonDialog(): Observable<any> {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Rescheduling reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }));
  }

  showCancelingReasonDialog(): Observable<any> {
    this.mediaDialogRef = this.dialogService.open(ActionDetailsComponent, {
      header: `Canceling reason`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        reason: 'reason',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => {
        return data.value;
      }));
  }

  onDuplicate() {
    if (this.checkReadonly()) {
      return;
    }
    if (this.configService.isConfigEnabled('TICKET_DUPLICATION_REASON_ENABLED')) {
      this.showDuplicationReasonDialog()
        .subscribe((reason: string) => {
          if (reason) {
            this.duplicateTicket(reason.trim())
              .subscribe();
          } else {
            this.notificationService.error('Reason should not be empty.');
          }
        });
    } else {
      this.duplicateTicket('')
        .subscribe();
    }
  }


  onDuplicatePrivate() {
    if (this.checkReadonly()) {
      return;
    }
    if (this.configService.isConfigEnabled('TICKET_DUPLICATION_REASON_ENABLED')) {
      this.showDuplicationReasonDialog()
        .subscribe((reason: string) => {
          if (reason) {
            this.duplicatePrivateTicket(reason.trim())
              .subscribe();
          } else {
            this.notificationService.error('Reason should not be empty.');
          }
        });
    } else {
      this.duplicatePrivateTicket('')
        .subscribe();
    }
  }


  onReportChange(report): void {

    if (this.loggedInUserIsHeadTech(this.ticket)) {
      if (report && this.primarySaveAction !== this.saveOptionsMap.saveAsCompletedAndClose()) {
        this.primarySaveAction = this.saveOptionsMap.saveAsCompletedAndClose();
        this.saveOptions = [
          this.saveOptionsMap.saveAndClose,
          this.saveOptionsMap.save
        ];
      } else {
        this.primarySaveAction = this.saveOptionsMap.save;
        this.saveOptions = [
          this.saveOptionsMap.saveAndClose,
          this.saveOptionsMap.saveAsCompletedAndClose()
        ];
      }
    }
  }

  onStatusChange(status): void {
    if (this.checkReadonly()) {
      return;
    }
    if (this.originalTicketStatus === 'COMPLETED') {
      this.confirmationService.confirm({
        message: 'Are you sure you want to change the status?',
        header: 'Status Change',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.statusChange(status);
        },
        reject: () => {
          this.ticket.status = this.originalTicketStatus;
        }
      });
    } else {
      this.statusChange(status);
    }

  }

  statusChange(status): void {
    if (this.ticketForm.dirty) {
      this.saveTicket().subscribe(() => {
        this.changeStatus(status)
          .subscribe(() => {
            this.context.loadTicket(this.ticket.id);
          });
      });
    } else {
      this.changeStatus(status)
        .subscribe(() => {
          this.context.loadTicket(this.ticket.id);
        });
    }
  }

  public async onInvoice() {
    if (this.checkReadonly()) {
      return;
    }
    if (this.ticket.id) {
      const amount = this.ticketInvoice.amount;
      if (isNaN(amount) || amount < 0) {
        this.notificationService.warning('Invalid invoice amount entered: should be 0 or greater than 0');
        this.isSavingInvoice = false;
        return;
      }

      try {
        await this.confirmZeroAmountInvoice();
        await this.confirmFutureServiceDateSelectionAction();
        await this.confirmNoTechOrAlreadyPayFormed();

        if (this.ticket.invoiceId) {
          this.updateInvoice(this.ticket.id);
        } else {
          this.invoice(this.ticket.id);
        }
      } catch (error) {
        console.log('Invoicing process was canceled');
      }
    } else {
      // todo Check this part, seems not used.
      this.saveTicket().subscribe((ticket: Ticket) => {
        this.invoice(this.ticket.id);
      });
    }
  }

  public onEstimate() {
    if (this.checkReadonly()) {
      return;
    }
    if (this.ticket.id) {
      if (this.ticket.estimateId) {
        if (this.ticketForm.dirty) {
          this.saveTicket().subscribe(() => {
            this.updateEstimate(this.ticket.id);
          });
        } else {
          this.updateEstimate(this.ticket.id);
        }
      } else {
        if (this.ticketForm.dirty) {
          this.saveTicket().subscribe(() => {
            this.estimate(this.ticket.id);
          });
        } else {
          this.estimate(this.ticket.id);
        }
      }
    }
  }

  onInvoiceChange(change): void {
    const amount = parseFloat(change.invoiceAmount);
    const expenses = parseFloat(change.invoiceExpenses);
    const tax = parseFloat(change.invoiceTax);
    const netTerm = parseFloat(change.invoiceNetTerm);
    const isInvoicePaid = change.isInvoicePaid;
    this.ticketInvoice.amount = amount;
    this.ticketInvoice.expenses = expenses;
    this.ticketInvoice.netTerm = netTerm;
    this.ticketInvoice.tax = tax;
    this.ticketInvoice.isInvoicePaid = isInvoicePaid;
    this.ticketInvoice.subtotal = this.invoiceService.calculateSubtotal(
      amount,
      expenses,
      tax
    );
  }

  addTag(tag: Tag): void {
    if (this.checkReadonly()) {
      return;
    }
    this.context.addTag(tag)
      .subscribe(
        () => {
          this.notificationService.success(Messages.TAG_SAVED);
        },
        err => this.notificationService.error(Messages.TAG_SAVED_ERROR, err)
      );
  }

  removeTag(tag: Tag): void {
    if (this.checkReadonly()) {
      return;
    }
    this.context.removeTag(tag)
      .subscribe(
        () => {
          this.notificationService.success(Messages.TAG_REMOVED);
        },
        err => this.notificationService.error(Messages.TAG_REMOVE_ERROR, err)
      );
  }

  onSendToPayFormClick() {
    if (this.checkReadonly()) {
      return;
    }
    this.sendToPayFormClicked = true;
    this.onSendToPayForm();
    // this.confirmFutureServiceDateSelectionAction()
    //   .then(() => this.onSendToPayForm())
    //   .catch(() => console.log('skipped sending the ticket to pay-form'));
  }

  onSendToPayForm(): void {
    if (this.ticketForm.dirty) {
      this.saveTicket().subscribe(() => {
        this.sendToPayForm();
      });
    } else {
      this.sendToPayForm();
    }
  }

  onEditPartner(): void {
    this.editPartnerVisible = true;
  }

  onEditPartnerModalHide(): void {
    this.editPartnerVisible = false;
  }

  onEditCustomer(): void {
    this.editCustomerVisible = true;
  }

  onEditCustomerModalHide(): void {
    this.editCustomerVisible = false;
  }

  canEditStatus(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_STATUS');
  }

  canChangeTags(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_TAGS');
  }

  canDuplicate(): boolean {
    return this.keycloakService.hasRole('TICKET_DUPLICATE') || this.keycloakService.hasRole('TICKET_DUPLICATE_PRIVATE');
  }

  hasDuplicateAndDuplicatePrivateRoles() {
    return this.keycloakService.hasRole('TICKET_DUPLICATE') && this.keycloakService.hasRole('TICKET_DUPLICATE_PRIVATE');
  }

  hasDuplicateRole() {
    return this.keycloakService.hasRole('TICKET_DUPLICATE') && !this.keycloakService.hasRole('TICKET_DUPLICATE_PRIVATE');
  }

  hasDuplicatePrivateRole() {
    return !this.keycloakService.hasRole('TICKET_DUPLICATE') && this.keycloakService.hasRole('TICKET_DUPLICATE_PRIVATE');
  }

  private sendToPayForm(): void {
    this.api.payForm.createForTicket(this.ticket.id)
      .subscribe((sentToPayform: SentToPayformModel[]) => {
        if (sentToPayform.length > 0) {
          const techToPayform = sentToPayform.reduce((map, payform) => {
            map[payform.technicianId] = payform;
            return map;
          }, {});
          this.ticket.technicians.forEach((technician) => {
            if (techToPayform[technician.id]) {
              technician.payFormCreatedAt = techToPayform[technician.id].payFormCreatedAt;
              technician.payFormId = techToPayform[technician.id].payFormId;
            }
          });
          this.notificationService.success(Messages.PAY_FORM_UPDATED);
          this.isPayFormed = true;
          this.ticketForm.controls['technicians'].disable();
        }
        this.refreshPage();
      }, error => {
        this.sendToPayFormClicked = false;
        this.notificationService.error(Messages.PAY_FORM_SAVE_ERROR, error);
      });
  }

  private duplicateTicket(reason: string) {
    return this.api.ticket.duplicate(this.ticket.id, reason)
      .pipe(
        tap(() => this.notificationService.success(Messages.TICKET_DUPLICATED)),
        catchError((err) => {
          this.notificationService.error(Messages.TICKET_DUPLICATED_FAILED, err);
          return observableOf();
        })
      );
  }

  private duplicatePrivateTicket(reason: string) {
    return this.api.ticket.duplicatePrivate(this.ticket.id, reason)
      .pipe(
        tap(() => this.notificationService.success(Messages.TICKET_DUPLICATED)),
        catchError((err) => {
          this.notificationService.error(Messages.TICKET_DUPLICATED_FAILED, err);
          return observableOf();
        })
      );
  }


  saveCustomer() {
    if (this.checkReadonly()) {
      return;
    }
    this.onEditCustomerModalHide();
    if (this.ticketForm.dirty) {
      this.onSave();
    }
  }

  private saveTicket(notifyCustomer = false, status: TicketStatus = null) {
    if (this.ticketForm.valid && this.quickInvoiceForm.valid && this.readonly === false) {
      if (this.ticketForm.valid && this.quickInvoiceForm.valid) {
        this.isSaving = true;
        const ticket = this.prepareTicketForSave();
        ticket.status = status;
        ticket.notifyCustomer = notifyCustomer;

        if (this.ticket.routes.routes?.length > 0 && new Date(this.ticket.serviceDate).getTime() !== new Date(ticket.serviceDate).getTime()) {
          if (!this.hasSentRoute()) {
            return this.save(ticket, this.ticket.id, status);
          } else {
            this.notifyRouteDialog = true;
          }
          return new Observable(observer => {
            this.routeChangeSubject.pipe(take(1)).subscribe((notifyRoute) => {
              ticket.notifyRouteChange = notifyRoute;
              return this.save(ticket, this.ticket.id, status).subscribe(observer);
            });
          });
        }

        return this.save(ticket, this.ticket.id, status);
      } else {
        FormHelpers.validateAllFormFields(this.ticketForm);
        FormHelpers.validateAllFormFields(this.quickInvoiceForm);
        return observableOf();
      }
    }
  }

  save(ticket, ticketId, status) {
    return this.api.ticket.save(ticket, ticketId, status)
      .pipe(
        tap((savedTicket: any) => {
          if (savedTicket) {
            this.ticket = savedTicket;
            this.afterTicketSave();
          }
          this.isSaving = false;
          this.notificationService.success(Messages.TICKET_SAVED);
        }),
        catchError((err) => {
          this.isSaving = false;
          this.notificationService.error(Messages.TICKET_SAVED_FAILED, err);
          return observableOf();
        })
      );
  }

  private afterTicketSave() {
    this.ticketForm.markAsPristine();
    this.ticketForm.markAsUntouched();
    this.calculateTicketInvoice();
  }

  private calculateTicketInvoice() {
    this.ticketInvoice = this.invoiceService.convertTicketToTicketInvoice(this.ticket);
  }

  private prepareTicketForSave() {
    const formValue = this.ticketForm.getRawValue();
    const invoiceValue = this.quickInvoiceForm.getRawValue();
    formValue.ticketId = this.ticket.id;
    let preparedTicket: Partial<Ticket> = {
      additionalNotes: formValue['additionalNotes'],
      appointmentDetails: formValue['appointmentDetails'],
      internalInfo: formValue['internalInfo'],
      issue: formValue['issue'],
      category: formValue['category'],
      report: formValue['report'],
      serviceDate: formValue['serviceDate'],
      serviceTimeEnd: formValue['serviceTimeEnd'],
      serviceTimeStart: formValue['serviceTimeStart'],
      technicians: formValue['technicians'],
      ticketPartnerStatus: formValue['ticketPartnerStatus'],
      timeNeeded: formValue['timeNeeded'],
      externalReferenceCode: formValue['externalReferenceCode'],
      checkReceived: formValue['checkReceived'],
      pickUpDelivery: formValue['pickUpDelivery'],
      recallGoingBack: formValue['recallGoingBack'],
      // invoice
      invoiceNetTerm: invoiceValue['invoiceNetTerm'],
      invoiceAmount: invoiceValue['invoiceAmount'],
      invoiceExpenses: invoiceValue['invoiceExpenses'],
      invoiceTax: invoiceValue['invoiceTax'],
    };
    if (this.configService.isEnabledWarranty()) {
      preparedTicket = {
        ...preparedTicket,
        deliveryDate: formValue['deliveryDate'],
        warrantyTerms: formValue['warrantyTerms']
      };
    }
    if (preparedTicket.technicians.length === 1) {
      preparedTicket.technicians[0].head = true;
    }

    const payByCheck = formValue['technicians'].some(tech => tech.check);
    if (!payByCheck) {
      preparedTicket.checkReceived = false;
    }

    return preparedTicket;
  }

  private changeStatus(status, close = false) {

    if (status === TicketStatus.RESCHEDULE) {
      return this.onReschedule();
    } else if (status === TicketStatus.CANCELED) {
      return this.onCancel();
    }

    return this.context.changeStatus(status)
      .pipe(
        tap(() => {
            this.ticket.status = status;
            if (close) {
              this.backOnTickets();
            } else {
              this.notificationService.success(Messages.TICKET_STATUS_UPDATED);
              this.refreshPage();
            }
          }
        ),
        catchError(err => {
          this.notificationService.error(Messages.TICKET_STATUS_UPDATED_FAILED, err);
          return observableOf();
        })
      );
  }

  private onReschedule() {
    return this.showReschedulingReasonDialog()
      .pipe(
        mergeMap(reason => {
          if (reason) {
            return this.reschedule(reason);
          }
          this.notificationService.error('Reason should not be empty.');
          return observableOf(null);
        })
      );
  }

  private reschedule(reason: string) {
    if (this.ticket.routes?.routes?.length > 0) {
      if (!this.hasSentRoute()) {
        return this.saveReschedule(reason, false);
      } else {
        this.notifyRouteDialog = true;
      }
      return new Observable(observer => {
        this.routeChangeSubject.pipe(take(1)).subscribe((notifyRoute) => {
          this.notifyRouteChange = notifyRoute;
          return this.saveReschedule(reason, this.notifyRouteChange).subscribe(observer);
        });
      });
    } else {
      return this.saveReschedule(reason, false);
    }
  }

  saveReschedule(reason, notify) {
    return this.context.reschedule(reason, notify)
      .pipe(
        tap(() => {
            this.ticket.status = TicketStatus.RESCHEDULE;
            this.notificationService.success(Messages.TICKET_STATUS_UPDATED);
            this.refreshPage();
          }
        ),
        catchError(err => {
          this.notificationService.error(Messages.TICKET_STATUS_UPDATED_FAILED, err);
          return observableOf();
        })
      );
  }

  private onCancel() {
    return this.showCancelingReasonDialog()
      .pipe(
        mergeMap(reason => {
          if (reason) {
            return this.cancel(reason);
          }
          this.notificationService.error('Reason should not be empty.');
          return observableOf(null);
        })
      );
  }

  routeChangeNotificationRequired(notify: boolean) {
    this.notifyRouteChange = notify;
    this.routeChangeSubject.next(notify); // Emit the user's response
    this.notifyRouteDialog = false; // Close the dialog
  }

  private cancel(reason: string) {
    if (this.ticket.routes?.routes?.length > 0) {
      if (!this.hasSentRoute()) {
        return this.saveReschedule(reason, false);
      } else {
        this.notifyRouteDialog = true;
      }
      return new Observable(observer => {
        this.routeChangeSubject.pipe(take(1)).subscribe((notifyRoute) => {
          this.notifyRouteChange = notifyRoute;
          return this.saveCancel(reason, this.notifyRouteChange).subscribe(observer);
        });
      });
    } else {
      return this.saveCancel(reason, false);
    }
  }

  saveCancel(reason, notify) {
    return this.context.cancel(reason, notify)
      .pipe(
        tap(() => {
            this.ticket.status = TicketStatus.CANCELED;
            this.notificationService.success(Messages.TICKET_STATUS_UPDATED);
            this.refreshPage();
          }
        ),
        catchError(err => {
          this.notificationService.error(Messages.TICKET_STATUS_UPDATED_FAILED, err);
          return observableOf();
        })
      );
  }

  onPdfAction(action) {
    if (this.checkReadonly()) {
      return;
    }
    if (this.pdfDownloadSpinner) {
      return;
    }
    switch (action) {
      case TicketPDFOptions.PDF:
      case TicketPDFOptions.PDF_WITHOUT_IMAGES:
      case TicketPDFOptions.PDF_IMAGES_ONLY:
      case TicketPDFOptions.PDF_WITHOUT_TOTAL_AMOUNT:
      case TicketPDFOptions.PDF_WITH_IMAGES_WITHOUT_TOTAL_AMOUNT:
        this.selectPDFOption(action);
        break;
      default:
        this.notificationService.error('Invalid PDF action');
    }
  }

  onPrintAction(action) {
    if (this.checkReadonly()) {
      return;
    }
    let printUrl = `/tickets/print?id=${this.ticketId}`;
    switch (action) {
      case TicketPrintOptions.PRINT:
        printUrl += '&showTotalAmount=true';
        break;
      case TicketPrintOptions.PRINT_WITHOUT_TOTAL_AMOUNT:
        printUrl += '&showTotalAmount=false';
        break;
      default:
        this.notificationService.error('Invalid print action');
    }

    this.api.ticket.markAsPrinted(this.ticket.id).subscribe(() => {
      window.open(printUrl, '_parent');
    });
  }

  onDuplicateAction(action) {
    switch (action) {
      case TicketDuplicateOptions.DUPLICATE:
        this.onDuplicate();
        break;
      case TicketDuplicateOptions.DUPLICATE_PRIVATE:
        this.onDuplicatePrivate();
        break;
      default:
        this.notificationService.error('Invalid duplicate action');
    }
  }

  async onDeleteTicket() {
    if (this.checkReadonly()) {
      return;
    }
    try {
      await this.confirmTicketDelete();
      await this.confirmTicketDeleteOperationAsRouteAlreadySent();
      this.deleteTicket();
    } catch (error) {
      console.log('Ticket deletion process was canceled');
    }
  }

  deleteTicket() {
    this.api.ticket.delete(this.ticket.id)
      .subscribe(() => {
        this.notificationService.success(Messages.TICKET_DELETED);
        this.router.navigate(['tickets']);
      });
  }

  onRecoverTicket() {
    if (this.checkReadonly()) {
      return;
    }
    this.api.ticket.recover(this.ticket.id)
      .subscribe(() => {
        this.notificationService.success(Messages.TICKET_RECOVERED);
        this.router.navigate(['tickets', this.ticketId]);
      }, error => {
        this.notificationService.error(error);
      });
  }

  onPay() {
    if (this.checkReadonly()) {
      return;
    }
    if (this.ticketForm.dirty) {
      this.saveTicket().subscribe(() => {
        this.sendToPayment();
      });
    } else {
      this.sendToPayment();
    }
  }

  sendToPayment() {
    this.confirmHasBillCreditCard()
      .then(() => this.confirmHasApprovalForPayment())
      .then(() => {
        this.router.navigate(['payment'], {
          queryParams: {
            itemId: this.ticket.id,
            itemType: 'ticket'
          }
        });
      })
      .catch(reason => {});
  }

  selectPDFOption(option: string) {
    if (this.checkReadonly()) {
      return;
    }
    const customerName = this.ticket.customer.name ?? '';
    const ticketId = this.ticket.id ?? '';
    const externalReferenceCode = this.ticket.externalReferenceCode ?? '';
    const timestamp = new Date().getTime();

    const fileName = `${customerName}_${ticketId}_${externalReferenceCode}_${timestamp}.pdf`;
    this.pdfDownloadSpinner = true;
    this.api.ticket.downloadPDF(this.ticket.id, option)
      .subscribe((value: Blob) => {
        download(value, fileName);
        this.pdfDownloadSpinner = false;
      }, (error) => {
        this.pdfDownloadSpinner = false;
        this.notificationService.error(Messages.TICKET_PDF_CREATED, error);
      });
  }


  private invoice(ticketId: number) {
    const ticketInvoice: TicketInvoice = {
      amount: this.ticketInvoice.amount ? this.ticketInvoice.amount : 0,
      expenses: this.ticketInvoice.expenses ? this.ticketInvoice.expenses : 0,
      netTerm: this.ticketInvoice.netTerm ? this.ticketInvoice.netTerm : 0,
      tax: this.ticketInvoice.tax ? this.ticketInvoice.tax : 0,
      isInvoicePaid: this.ticketInvoice.isInvoicePaid ? this.ticketInvoice.isInvoicePaid : false
    };

    if (ticketId) {
      this.readonly = true;
      this.isSavingInvoice = true;
      this.api.ticket.invoice(ticketId, ticketInvoice)
        .subscribe(ticketInvoiceResponse => {
          this.ticket.invoiceId = ticketInvoiceResponse.invoiceId;
          this.ticket.invoiceCreatedAt = ticketInvoiceResponse.invoiceCreatedAt;
          this.ticket.invoiceTotalAmount = ticketInvoiceResponse.totalAmount;
          this.readonly = false;
          if (this.ticketForm.dirty) {
            this.saveTicket().subscribe(() => this.refreshPage());
          } else {
            // reload ticket here
            this.refreshPage();
          }
          this.notificationService.success(Messages.TICKET_INVOICED);
        }, err => {
          this.readonly = false;
          this.notificationService.error(Messages.INVOICE_CANT_CREATE, err);
        }, () => {
          this.isSavingInvoice = false;
        });
    } else {
      this.notificationService.error(Messages.INVOICE_CANT_CREATE_NOID);
    }
  }

  private updateInvoice(ticketId: number, saveTicket: boolean = true, refreshTicket: boolean = true, navigateBack: boolean = false) {
    const ticketInvoice: TicketInvoice = {
      amount: this.ticketInvoice.amount ? this.ticketInvoice.amount : 0,
      expenses: this.ticketInvoice.expenses ? this.ticketInvoice.expenses : 0,
      netTerm: this.ticketInvoice.netTerm ? this.ticketInvoice.netTerm : 0,
      tax: this.ticketInvoice.tax ? this.ticketInvoice.tax : 0,
      isInvoicePaid: this.ticketInvoice.isInvoicePaid ? this.ticketInvoice.isInvoicePaid : false
    };
    this.readonly = true;
    this.isSavingInvoice = true;
    this.api.ticket.updateInvoice(ticketId, ticketInvoice)
      .subscribe(ticketInvoiceResponse => {
        this.ticket.invoiceTotalAmount = ticketInvoiceResponse.totalAmount;
        this.notificationService.success(Messages.TICKET_INVOICED);
        this.readonly = false;
        if (saveTicket && this.ticketForm.dirty) {
          this.saveTicket().subscribe(() => {
            if (refreshTicket) {
              this.refreshPage();
            } else if (navigateBack) {
              window.history.back();
            }
          });
        } else {
          if (refreshTicket) {
            this.refreshPage();
          } else if (navigateBack) {
            window.history.back();
          }
        }
      }, err => {
        this.readonly = false;
        this.notificationService.error(Messages.INVOICE_CANT_CREATE, err);
      }, () => {
        this.isSavingInvoice = false;
      });
  }

  private updateEstimate(ticketId: number) {
    const ticketInvoice: TicketInvoice = {
      amount: this.ticketInvoice.amount ? this.ticketInvoice.amount : 0,
      expenses: this.ticketInvoice.expenses ? this.ticketInvoice.expenses : 0,
      tax: this.ticketInvoice.tax ? this.ticketInvoice.tax : 0
    };
    this.readonly = true;
    this.isSavingInvoice = true;
    this.api.ticket.updateEstimate(ticketId, ticketInvoice)
      .subscribe(ticketInvoiceResponse => {
        this.ticket.estimateTotalAmount = ticketInvoiceResponse.totalAmount;
        this.notificationService.success(Messages.ESTIMATE_UPDATED);
        this.refreshPage();
      }, err => {
        this.notificationService.error(Messages.ESTIMATE_CANT_UPDATED, err);
      }, () => {
        this.isSavingInvoice = false;
        this.readonly = false;
      });
  }

  private estimate(ticketId: number): void {
    const ticketInvoice: TicketInvoice = {
      amount: this.ticketInvoice.amount ? this.ticketInvoice.amount : 0,
      expenses: this.ticketInvoice.expenses ? this.ticketInvoice.expenses : 0,
      tax: this.ticketInvoice.tax ? this.ticketInvoice.tax : 0
    };
    if (ticketId) {
      this.readonly = true;
      this.isSavingInvoice = true;
      this.api.ticket.estimate(ticketId, ticketInvoice)
        .subscribe(ticketInvoiceResponse => {
          this.ticket.estimateId = ticketInvoiceResponse.invoiceId;
          this.ticket.estimateCreatedAt = ticketInvoiceResponse.invoiceCreatedAt;
          this.ticket.estimateTotalAmount = ticketInvoiceResponse.totalAmount;
          // reload ticket here
          this.refreshPage();
          this.notificationService.success(Messages.INVOICE_ESTIMATED);
        }, err => {
          this.notificationService.error(Messages.ESTIMATE_CANT_CREATE, err);
          this.readonly = false;
          this.isSavingInvoice = false;
        }, () => {
          this.readonly = false;
          this.isSavingInvoice = false;
        });
    } else {
      this.notificationService.error(Messages.ESTIMATE_CANT_CREATE_NOID);
    }
  }

  protected sendToCustomer(option: TicketPDFOptions) {
    if (this.checkReadonly()) {
      return;
    }
    const hasEmail = this.ticket?.customer?.contactPersons?.some(contactPerson =>
      contactPerson?.contacts?.some(contact => contact?.type === 'EMAIL')
    );

    if (hasEmail) {
      // Emails found, proceed with sending the ticket
      this.sendPdfToCustomer(option);
    } else {
      // No email contacts found, open dialog to enter email
      this.isCustomerEmailMissing = true;
      this.openCustomEmailDialog(option);
    }
  }

  sendPdfToCustomer(option) {
    if (this.checkReadonly()) {
      return;
    }
    let email = null;
    if (this.isCustomerEmailMissing) {
      email = this.customEmailForm.get('email').value;
    }
    this.api.ticket.sendToCustomer(this.ticketId, option, email)
      .subscribe(value => {
        this.notificationService.success(Messages.TICKET_SENT);
      }, () => {
        this.notificationService.error(Messages.TICKET_SENT_ERROR);
      });
    this.isCustomerEmailMissing = false;
    this.customEmailDialog = false;
  }

  private sendToPartner(option: TicketPDFOptions) {
    if (this.checkReadonly()) {
      return;
    }
    if (!this.ticket.mainPartnerContactPerson) {
      this.notificationService.error('Account does not have a main contact person or an email set.');
      return;
    }
    this.api.ticket.sendToPartner(this.ticketId, option)
      .subscribe(value => {
        this.notificationService.success(Messages.TICKET_SENT);
      }, () => {
        this.notificationService.error(Messages.TICKET_SENT_ERROR);
      });
  }

  sendToEmail() {
    if (this.checkReadonly()) {
      return;
    }
    const email: string = this.customEmailForm.get('email').value;
    const option = this.customEmailForm.get('option').value;
    this.api.ticket.sendToEmail(this.ticketId, option, email)
      .subscribe(value => {
        this.notificationService.success(Messages.TICKET_SENT);
      }, () => {
        this.notificationService.error(Messages.TICKET_SENT_ERROR);
      }, () => {
        this.customEmailDialog = false;
      });
  }

  openCustomEmailDialog(option: TicketPDFOptions) {
    this.customEmailDialog = true;
    this.customEmailForm = this.fb.group({
      email: new UntypedFormControl('', Validators.email),
      option: new UntypedFormControl({value: option, disabled: false}),
    });
  }

  getCustomEmailFormControl(control: string): UntypedFormControl {
    return this.customEmailForm.get(control) as UntypedFormControl;
  }

  onHideSign() {
    this.customerSignDialog = false;
  }

  onSign() {
    if (this.checkReadonly()) {
      return;
    }
    this.customerSignDialog = true;
  }

  onSaveReport() {
    this.onSave();
  }

  backOnTickets() {
    this.router.navigate(['tickets']);
  }

  revisionCollapsedChange(collapsed) {
    if (!collapsed && !this.revisionLoaded) {
      this.api.ticket.getRevision(this.ticketId)
        .subscribe(revisionResponse => {
          this.ticket.revision = revisionResponse;
          this.revisionLoaded = true;
        }, () => {
          this.notificationService.error(Messages.TICKET_REVISION_LOAD_FAILED);
        });
    }
  }

  private refreshPage() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  updatePayForm() {
    if (this.checkReadonly()) {
      return;
    }
    this.saveTicket().subscribe(_ => this.refreshPage());
  }

  isPayFormChange() {
    const formValue = this.ticketForm.getRawValue();
    const technicians = formValue['technicians'];
    const isPayFormChange = new Set<boolean>;
    technicians.forEach(
      technician => {
        const ticketTechnician = this.ticket.technicians.find(tech => tech.id === technician.id);
        isPayFormChange.add(technician.incomeCash !== ticketTechnician?.incomeCash);
        isPayFormChange.add(technician.bill !== ticketTechnician?.bill);
        isPayFormChange.add(technician.check !== ticketTechnician?.check);
        isPayFormChange.add(technician.creditCard !== ticketTechnician?.creditCard);
        isPayFormChange.add(technician.expenses !== ticketTechnician?.expenses);
        isPayFormChange.add(technician.expensesDescription !== ticketTechnician?.expensesDescription);
        isPayFormChange.add(technician.technicianExpenses !== ticketTechnician?.technicianExpenses);
        isPayFormChange.add(technician.technicianExpensesDescription !== ticketTechnician?.technicianExpensesDescription);
        isPayFormChange.add(technician.partsPickup !== ticketTechnician?.partsPickup);
      }
    );
    return isPayFormChange.has(true);
  }

  isInvoiceChange() {
    if (this.ticket.invoiceId) {
      const invoiceValue = this.quickInvoiceForm.getRawValue();
      return (
        invoiceValue['invoiceNetTerm'] !== this.ticket?.invoiceNetTerm ||
        invoiceValue['invoiceAmount'] !== this.ticket?.invoiceAmount ||
        invoiceValue['invoiceExpenses'] !== this.ticket?.invoiceExpenses ||
        invoiceValue['invoiceTax'] !== this.ticket?.invoiceTax
      );
    }
    return false;
  }

  cancelInvoiceChange() {
    const invoiceValue = this.quickInvoiceForm.controls;

    const invoiceNetTerm = invoiceValue['invoiceNetTerm'];
    const invoiceAmount = invoiceValue['invoiceAmount'];
    const invoiceExpenses = invoiceValue['invoiceExpenses'];
    const invoiceTax = invoiceValue['invoiceTax'];

    invoiceNetTerm.setValue(this.ticket?.invoiceNetTerm);
    invoiceAmount.setValue(this.ticket?.invoiceAmount);
    invoiceExpenses.setValue(this.ticket?.invoiceExpenses);
    invoiceTax.setValue(this.ticket?.invoiceTax);
  }

  checkReadonly() {
    if (this.readonly) {
      this.notificationService.warning('This ticket is readonly. You can\'t save changes.');
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.mediaDialogRef) {
      this.mediaDialogRef.close();
    }
  }

  filterStatuses(statuses: any[]): any[] {
    if (this.ticket.serviceDate && this.keycloakService.hasRole('TICKET_VIEW_STATUS_ASSIGNED')) {
      return statuses.filter(status => status.value !== TicketStatus.ASSIGNED);
    }
    return statuses;
  }

  onUpdateCustomerDetails(event) {
    this.api.ticket.updateCustomerDetails(this.ticketId, event).pipe(
      map((updatedCustomer: Customer) => {
        updatedCustomer.contactPersons.map(person => person.id === this.ticket.mainCustomerContactPerson.id ? person.isMain = true : person);
        this.context.customer$.next(updatedCustomer);
        this.context.mainCustomerContactPerson$.next(updatedCustomer.contactPersons?.find(person => person.id === this.ticket.mainCustomerContactPerson.id));
        this.customerDetailsUpdateDialog = false;
      }),
      catchError(errorMessage => {
        return observableThrowError(errorMessage);
      })
    ).subscribe();
  }

  onHideCustomerDetailsUpdate() {
    this.customerDetailsUpdateDialog = false;
  }

  onCustomerDetailsUpdate() {
    this.customerDetailsUpdateDialog = true;
  }

  private hasSentRoute(): boolean {
    const routes = this.ticket?.routes?.routes;
    return routes?.some(it => !!it.sendDate) ?? false;
  }

  /*region confirmation dialogs*/
  private confirmHasBillCreditCard(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const formArray = this.ticketForm.controls['technicians'] as FormArray;
      let totalBill = 0;
      let totalCreditCard = 0;

      formArray.controls.forEach(control => {
        const bill = +control.get('bill').value || 0;
        const creditCard = +control.get('creditCard').value || 0;
        totalBill += bill;
        totalCreditCard += creditCard;
      });

      if (totalBill && totalCreditCard) {
        const sum = totalBill + totalCreditCard;
        const message = `There are amounts in both Bill <b>$${totalBill}</b> and Credit Card <b>$${totalCreditCard}</b> fields. ` +
          `If you proceed, the total amount will be <b>$${sum}</b>. ` +
          'You can adjust the amounts manually or cancel and correct the fields.';
        this.confirmationService.confirm({
          message,
          header: 'Payment confirmation',
          icon: 'pi pi-exclamation-triangle',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmHasApprovalForPayment(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.ticket.partner?.payment?.approvalRequired) {
        this.confirmationService.confirm({
          message: 'This account requires approval prior to charging',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmSaveAndVerify(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Technician(s) pay not processed and/or Invoice amount is 0. <br> Would you like to proceed?',
        header: 'Save / Verify warning',
        icon: 'pi pi-exclamation-triangle',
        closeOnEscape: true,
        blockScroll: true,
        dismissableMask: true,
        accept: () => resolve(),
        reject: () => reject(),
      });
    });
  }

  private confirmTicketDelete(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the ticket?',
        header: 'Deleting ticket',
        icon: 'pi pi-exclamation-triangle',
        closeOnEscape: true,
        blockScroll: true,
        dismissableMask: true,
        accept: () => resolve(),
        reject: () => reject(),
      });
    });
  }

  private confirmTicketDeleteOperationAsRouteAlreadySent(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.hasSentRoute()) {
        this.confirmationService.confirm({
          message: 'There are some routes associated with the ticket that are being sent to technicians?',
          header: 'Routes associated with the ticket',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Delete and Resend',
          rejectLabel: 'Cancel',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmZeroAmountInvoice(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.ticketInvoice.amount === 0) {
        this.confirmationService.confirm({
          message: 'Invoice amount is 0. Are you sure you want to save the invoice?',
          header: 'Invoice creating',
          icon: 'pi pi-exclamation-triangle',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmNoTechOrAlreadyPayFormed() {
    return new Promise<void>((resolve, reject) => {
      const isPayFormed = this.ticket.technicians?.some(technician => technician.payFormId === null);
      const noTech = this.ticket.technicians.length === 0;
      if (noTech || isPayFormed) {
        this.confirmationService.confirm({
          message: 'No Amount/Payment Processed for Technician. Are you sure you want to create an invoice?',
          header: 'Invoice creating',
          icon: 'pi pi-exclamation-triangle',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmForgotTechnician() {
    return new Promise<void>((resolve, reject) => {
      const techFormArray = this.ticketForm.controls['technicians'] as FormArray;
      const noTech = techFormArray?.length === 0;
      if (noTech && this.ticketForm.controls['serviceDate'].value) {
        this.confirmationService.confirm({
          message: `<span class="text-red-600 font-semibold text-3xl">No technicians assigned</span>`,
          header: 'Save and Close',
          icon: 'pi pi-exclamation-triangle text-red-600 text-6xl',
          acceptLabel: 'Continue',
          rejectLabel: 'Cancel',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmFutureServiceDateSelectionAction(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const serviceDate = this.ticketForm.get('serviceDate').getRawValue();
      const endOfToday = dayjs().utc().endOf('day');
      const isFutureServiceDateSelected = serviceDate && dayjs(serviceDate).isAfter(endOfToday);
      if (isFutureServiceDateSelected) {
        this.confirmationService.confirm({
          message: 'Work order is scheduled for a future date. Proceed?',
          header: 'Confirmation on future service date selection',
          icon: 'pi pi-exclamation-triangle',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmPayFormChanges() {
    return new Promise<void>((resolve, reject) => {
      if (this.ticket.isSentToPayForm && this.isPayFormChange()) {
        this.confirmationService.confirm({
          message: `<span class="text-red-600 font-semibold text-3xl">Pay Form Amount Changed. Update and Save?</span>`,
          header: 'Technicians pay form changes',
          icon: 'pi pi-exclamation-triangle text-red-600 text-6xl',
          acceptLabel: 'Yes',
          rejectLabel: 'Cancel',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        resolve();
      }
    });
  }

  private confirmInvoiceChanges() {
    return new Promise<void>((resolve, reject) => {
      if (this.isInvoiceChange()) {
        this.confirmationService.confirm({
          message: `<span class="text-red-600 font-semibold text-3xl">Invoice Amount Changed. Update and Save?</span>`,
          header: 'Invoice amount changes',
          icon: 'pi pi-exclamation-triangle text-red-600 text-6xl',
          acceptLabel: 'Yes',
          rejectLabel: 'Cancel',
          closeOnEscape: true,
          blockScroll: true,
          dismissableMask: true,
          accept: () => resolve(),
          reject: () => reject(),
        });
      } else {
        reject();
      }
    });
  }

  /*endregion*/
}
