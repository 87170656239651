import {Injectable} from '@angular/core';
import {AddressDetails} from '../../../api/model/Address.model';
import {ApiService} from '../../../api/service/api.service';

declare var google: any;

@Injectable()
export class MapService {

  private geocoder: google.maps.Geocoder;

  constructor(private apiService: ApiService) {
    this.geocoder = new google.maps.Geocoder();
  }

  getAutocompleteForElement(element: HTMLInputElement, options: { types: string[] }) {
    return new google.maps.places.Autocomplete(element, options);
  }

  parsePlaceToAddress(place): AddressDetails {
    const changedAddress = new AddressDetails();
    if (place) {
      changedAddress.addressFormatted = place.formatted_address;
      changedAddress.lat = place.geometry.location.lat();
      changedAddress.lng = place.geometry.location.lng();
      changedAddress.country = this.parseAddressComponent(place, 'country', 'long');

      // NYC googlemaps uses sublocality_level_1 for the name city https://stackoverflow.com/questions/58561870/getting-city-from-borough-using-google-maps-api
      changedAddress.city = this.parseAddressComponent(place, 'locality', 'long') || this.parseAddressComponent(place, 'sublocality_level_1', 'long');
      changedAddress.apt = this.parseAddressComponent(place, 'subpremise', 'short') || null;
      changedAddress.postalCode = this.parseAddressComponent(place, 'postal_code', 'short');
      changedAddress.state = this.parseAddressComponent(place, 'administrative_area_level_1', 'short');
      changedAddress.bld = this.parseAddressComponent(place, 'street_number', 'short');
      changedAddress.street = this.parseAddressComponent(place, 'route', 'short');
    } else {
      changedAddress.addressFormatted = '';
    }
    return changedAddress;
  }

  parseAddressComponent(address, component, type) {
    let element = null;
    const components = address.address_components;

    components.forEach((addressComponent) => {
      if (addressComponent.types[0] === component) {
        element = type === 'short' ? addressComponent.short_name : addressComponent.long_name;
      }
    });

    return element;
  }

  findAddressLatLng(address: string | AddressDetails) {
    const formattedAddress = this.getFormattedAddress(address);
    return this.readCoordsFromAddress(formattedAddress);
  }

  findCoordsByPlace(place) {
    return this.readCoordsFromPlace(place);
  }

  getFormattedAddress(address: string | AddressDetails): string {
    if (address) {
      return address instanceof AddressDetails ? address.getFormattedAddress() : address;
    } else {
      return '';
    }
  }

  private readCoordsFromAddress(address: string) {

    try {
      return this.readFromBackendCache(address);
    } catch (e) {
      return this.readFromGoogleApi({
        address
      });
    }
  }

  private readFromGoogleApi(request: { address: string }) {
    return new Promise<{ lng: number, lat: number }>((resolve) => {
      this.geocoder.geocode(request, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const place = results[0];
          resolve(this.readCoordsFromPlace(place));
        }
      });
    });
  }

  private readFromBackendCache(address: string) {
    return this.apiService.geocodingApi.getCoordinates(address)
      .toPromise();
  }

  private readCoordsFromPlace(place) {
    const location = place.geometry.location;
    return {
      lng: location.lng(),
      lat: location.lat()
    };
  }
}
