<div [formGroup]="currentFormGroup" class="grid align-items-center">

  <div class="lg:col-1 sm:col-12 col-12">
    <tj-field label="Route #" [control]="fcId">
      <input pInputText placeholder="42" formControlName="id"/>
    </tj-field>
  </div>

  <div class="lg:col-2 sm:col-12 col-12">
    <tj-field label="Date" [control]="fcDate">
      <tj-datepicker
        [onlyFuture]="false"
        placeholder="Date"
        formControlName="date"></tj-datepicker>
    </tj-field>
  </div>

  <div class="lg:col-2 sm:col-12 col-12">

    <tj-field label="Technician" [control]="fcTechnicianId">
      <tj-technician-combobox formControlName="technicianId"></tj-technician-combobox>
    </tj-field>
  </div>

  <div class="lg:col-1 sm:col-12 col-12">
    <tj-field label="Ticket #" [control]="fcTicketId">
      <input pInputText placeholder="42" formControlName="ticketId"/>
    </tj-field>
  </div>

  <div class="lg:col-1 sm:col-12 col-12 mt-4">
    <tj-field [control]="fcIsEdited">
      <p-checkbox binary="true" label="Edited" formControlName="isEdited"></p-checkbox>
    </tj-field>
  </div>

</div>
