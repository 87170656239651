import {Component, Input, OnChanges} from '@angular/core';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Customer} from '../../../../api/model/Customer.model';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {TicketRouteDetailsModel} from '../../../../api/model/ticket/ticket.route.details.model';

@Component({
  selector: 'tj-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnChanges {
  private readonly zipCodeRegExpFiveDigit = new RegExp('^\\d{5}(\\D.*)?$');

  @Input() customer: Customer;
  @Input() mainContactPerson: ContactPerson;
  @Input() ticketId: number;
  @Input() disabled: boolean;
  @Input() readonly = false;
  @Input() ticketServiceDate: Date | string;

  coveredAreaCodesName: string;
  coveredAreaCodesId: string;


  showSecondaryContactPersons = true;

  secondaryContactPersons: ContactPerson[] = [];

  customerAddressZipCode: string;

  customerAddressConfirm: boolean;

  constructor(private context: TicketEditContextService,
              private api: ApiService,
              public configService: ConfigService,
              private keycloakService: TjKeycloakService,
              private notificationService: NotificationService) {
  }

  ngOnChanges() {
    if (this.customer && this.customer.getSecondaryContactPersons) {
      this.secondaryContactPersons = this.customer.getSecondaryContactPersons();
      this.customerAddressConfirm = this.customer.addressConfirmed;
      this.updateTerritoryDetails();
    }
  }

  private updateTerritoryDetails() {
    if (this.configService.isEnabledTerritory()) {
      const customerPostalCode = this.customer.address?.postalCode;
      const zipCode = customerPostalCode?.match(this.zipCodeRegExpFiveDigit)?.[0]?.slice(0, 5);

      if (zipCode) {
        this.customerAddressZipCode = zipCode;
        this.coveredAreaCodesName = this.customer.address?.areas?.map(it => it.code).join(',');
        this.coveredAreaCodesId = this.customer.address?.areas?.map(it => it.id).join(',');
        return; // Exit early if zip code is found
      }
    }

    // Reset values if territory is not enabled or zip code is not found
    this.customerAddressZipCode = null;
    this.coveredAreaCodesName = null;
    this.coveredAreaCodesId = null;
  }

  onShowSecondaryContactPersons() {
    this.showSecondaryContactPersons = !this.showSecondaryContactPersons;
  }

  onCustomerAddressConfirm($event: any): void {
    if (this.readonly) {
      this.notificationService.warning('This ticket is readonly. You can\'t save changes.');
      return;
    }
    this.context.confirmCustomerAddress(this.ticketId, $event.checked).subscribe();
  }

  showAddressConfirm(): boolean {
    return !this.customer.addressConfirmed && this.keycloakService.hasRole('TICKET_VIEW_ASSIGNED');
  }
}
