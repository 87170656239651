import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {AddressDetails} from '../../../../api/model/Address.model';
import {RouteMetricsService} from '../../route.metrics';
import {RouteDetails} from '../../models/route-data';
import {MapService} from '../../../shared/services/map.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RouteService} from '../../route.service';
import {TechnicianTicketDetails} from '../../../../api/model/technician/technician.ticket.details';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {TechnicianTicketsResponse} from '../../../../api/model/technician/technician.tickets.response';
import {RouteListModel, StopItemModel} from '../../../../api/model/route/route.line.model';
import * as dayjs from 'dayjs';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {filter} from 'rxjs/operators';
import {TicketEditServiceTimeModalComponent} from '../../../ticket/components';
import {Router} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ConfirmationService} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {AddressedMarker} from './addressed-marker.model';
declare var google: any;

@Component({
  selector: 'tj-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {

  public readonly ORIGIN_ICON: string = 'assets/images/flag_start.png';

  public readonly DESTINATION_ICON: string = 'assets/images/flag_end.png';

  public readonly TECH_HOME_ICON: string = 'assets/images/home.png';

  private readonly ADDRESS_COUNT: number = 2;

  public selectedTechnician: TechnicianMinimalListItem;

  private directionsService = new google.maps.DirectionsService();

  private directionsRenderer = new google.maps.DirectionsRenderer();

  @ViewChild('gmap') public map: google.maps.Map;

  @Input() public route: RouteListModel;

  public mapDefaultConfig = {
    zoom: 15,
    center: new google.maps.LatLng(40.18609961626319, 44.51486725725317),
  };

  public routeDetails: RouteDetails;

  routeOptions = {
    avoidTolls: false,
    avoidHighways: false,
    avoidFerries: false,
    optimizeRoute: false,
  };

  public roundTrip = false;

  public markers: google.maps.Marker[] = [];

  currentDateRouteId: number;

  legs: google.maps.DirectionsLeg[];

  selectedLeg: google.maps.DirectionsLeg;

  visibleLegSidebar = false;

  showMap = false;

  mediaDialogRef: DynamicDialogRef;

  formGroup: UntypedFormGroup;

  baseGroup: UntypedFormGroup;

  optionsForm: UntypedFormGroup;

  homePoint: AddressedMarker = new AddressedMarker(new AddressDetails(), new google.maps.Marker({
      zIndex: 1000,
      animation: google.maps.Animation.BOUNCE,
      icon: {
        url: this.TECH_HOME_ICON,
      },
    })
  );

  startPoint: AddressedMarker = new AddressedMarker(new AddressDetails(), new google.maps.Marker({
      zIndex: 1000,
      icon: {
        url: this.ORIGIN_ICON,
      },
    })
  );

  endpoint: AddressedMarker = new AddressedMarker(new AddressDetails(), new google.maps.Marker({
      zIndex: 1000,
      icon: {
        url: this.DESTINATION_ICON,
      },
    })
  );

  public stops: AddressedMarker[] = [];

  constructor(private apiService: ApiService, private notificationService: NotificationService,
              public config: ConfigService,
              private datePipe: DatePipe,
              private routeMetricsService: RouteMetricsService,
              private routeService: RouteService,
              private router: Router,
              private tjKeycloakService: TjKeycloakService,
              private confirmationService: ConfirmationService,
              private fb: UntypedFormBuilder,
              public dialogService: DialogService,
              private mapService: MapService) {
  }

  ngOnInit() {
    this.prepareForms();
    this.prepareBaseForm();
    this.prepareOptionsForm();
    if (this.tjKeycloakService.hasRole('ROUTES_PLANING_VIEW_ASSIGNED')) {
      this.getTechnicianTodayRoute();
    }
    if (this.route) {
      this.openForRoute();
      return;
    }

    for (let i = 1; i <= this.ADDRESS_COUNT; i++) {
      this.stops.push(
        new AddressedMarker(new AddressDetails(), new google.maps.Marker({
            title: null,
            label: {text: '' + i}
          })
        )
      );
      this.markers.push(new google.maps.Marker());
    }
  }

  private prepareForms() {
    this.formGroup = this.fb.group({
      date: this.fb.control('', [Validators.required]),
      email: this.fb.control(null, [Validators.required, Validators.email]),
      technicianId: this.fb.control(''),
    });
    this.formGroup.get('date').valueChanges.subscribe(value => {
      if (this.selectedTechnician && !this.route) {
        this.selectTechnician(this.selectedTechnician);
      }
    });
  }

  private prepareOptionsForm() {
    this.optionsForm = this.fb.group({
      avoidTolls: this.fb.control(this.routeOptions.avoidTolls),
      avoidHighways: this.fb.control(this.routeOptions.avoidHighways),
      avoidFerries: this.fb.control(this.routeOptions.avoidFerries),
      optimizeRoute: this.fb.control(this.routeOptions.optimizeRoute),
    });
  }

  private prepareBaseForm() {
    this.baseGroup = this.fb.group({
      startAddress: this.fb.control(this.startPoint.address.addressFormatted),
      endAddress: this.fb.control(this.endpoint.address.addressFormatted),
      address: this.fb.control({value: null, disabled: true}),
      stops: this.fb.array(this.stops.map(it => this.generateStopFormGroup(it))),
      asStart: this.fb.control(this.startPoint.asStart),
      asEnd: this.fb.control(this.startPoint.asEnd),
      roundTrip: this.fb.control(this.roundTrip),
    });
    this.baseGroup.get('roundTrip').valueChanges.subscribe(checked => {
      if (checked) {
        this.endAddressChange({address: this.startPoint.address, valid: true});
        this.disableEndAddress();
        this.uncheckStopsAsEnd();
      } else {
        this.enableEndAddress();
      }
    });
    this.setRoundTrip(this.roundTrip);
  }

  private generateStopFormGroup(addressedMarker: AddressedMarker) {
    return this.fb.group({
      id: this.fb.control(addressedMarker.id),
      ticketId: this.fb.control(addressedMarker.ticketId),
      serviceTimeStart: this.fb.control(addressedMarker.serviceTimeStart),
      serviceTimeEnd: this.fb.control(addressedMarker.serviceTimeEnd),
      timeNeeded: this.fb.control(addressedMarker.timeNeeded),
      customer: this.fb.control(addressedMarker.customer),
      address: this.fb.control(addressedMarker.address.addressFormatted),
      asStart: this.fb.control(addressedMarker.asStart),
      asEnd: this.fb.control(addressedMarker.asEnd),
    });
  }


  startAddressChange(event: { address: AddressDetails, valid: boolean }) {
    this.startPoint.address = {...event.address};
    this.startPoint.marker.setOptions({position: {...event.address}});
    this.baseGroup.get('startAddress').setValue(event.address.addressFormatted);
    if (this.isRoundTrip()) {
      this.endAddressChange(event);
    } else {
      this.updateMapBounds();
    }
  }

  endAddressChange(event: { address: AddressDetails, valid: boolean }) {
    this.endpoint.address = {...event.address};
    this.endpoint.marker.setOptions({position: {...event.address}});
    this.baseGroup.get('endAddress').setValue(event.address.addressFormatted);
    this.updateMapBounds();
  }

  techHomeAddressChange(event: { address: AddressDetails, valid: boolean }) {
    this.homePoint.address = {...event.address};
    this.homePoint.marker.setOptions({position: {...event.address}});
    this.baseGroup.get('address').setValue(event.address.addressFormatted);
    this.updateMapBounds();
  }


  getStopsControls() {
    return (this.baseGroup.get('stops') as UntypedFormArray).controls;
  }

  routesRestricted() {
    return !this.tjKeycloakService.hasRole('ROUTES_PLANING_VIEW_ASSIGNED');
  }

  public selectTechnician(technician: TechnicianMinimalListItem): void {
    this.selectedTechnician = technician;
    this.loadTechnicianAddress(technician.id);
    const date = this.formGroup.get('date').getRawValue();
    this.homePoint.address = {...technician.address};
    this.homePoint.marker.setOptions({position: {...technician.address}});
    if (!date) {
      return;
    }
    this.apiService.technician.getTechnicianTickets(technician.id, date)
      .subscribe((response: TechnicianTicketsResponse) => {
        const routingDetails: TechnicianTicketDetails[] = response.tickets;
        if (routingDetails.length == 0) {
          this.stops = [];
        }
        if (routingDetails.length > this.stops.length) {
          const diff: number = routingDetails.length - this.stops.length;
          for (let i = 0; i < diff; i++) {
            this.onAddAddress();
          }
        }
        for (let i = 0; i < routingDetails.length; i++) {
          const now = new Date();
          now.setHours(routingDetails[i].timeNeeded);
          now.setMinutes(0);
          this.stops[i].ticketId = routingDetails[i].id;
          this.stops[i].serviceTimeStart = routingDetails[i].serviceTimeStart;
          this.stops[i].serviceTimeEnd = routingDetails[i].serviceTimeEnd;
          this.stops[i].timeNeeded = routingDetails[i].timeNeeded;
          this.stops[i].customer = routingDetails[i].customer;
          this.stops[i].address = {
            id: undefined,
            getFormattedAddress: routingDetails[i].address.getFormattedAddress, ...routingDetails[i].address
          };
        }
        this.prepareBaseForm();
        this.setTechAddress(response?.address?.addressFormatted);
        this.setTechEmail(response?.email);
        this.checkHomeAsStart();
        this.checkHomeAsEnd();
        this.setRoundTrip(true);
      });
  }

  onAddAddress() {
    const newIndex = this.stops.length + 1;
    const newAddressedMarker = new AddressedMarker(new AddressDetails(), new google.maps.Marker({
        title: null,
        label: {text: '' + newIndex}
      })
    );
    this.stops = [...this.stops, newAddressedMarker];
    const stopsFormArray = this.baseGroup.controls['stops'] as UntypedFormArray;
    stopsFormArray.push(this.generateStopFormGroup(newAddressedMarker));
  }

  public onAddressChange(event: { address: AddressDetails, valid: boolean }, index: number): void {
    const addressModel = event.address;
    this.stops[index].marker
      .setOptions({position: new google.maps.LatLng({lat: addressModel.lat, lng: addressModel.lng})});
    this.stops[index].address = {...addressModel};
    this.markers[index].setOptions({position: new google.maps.LatLng({lat: addressModel.lat, lng: addressModel.lng})});
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getStopsControls(), event.previousIndex, event.currentIndex);
    moveItemInArray(this.stops, event.previousIndex, event.currentIndex);
    this.updateMarkerLabels();
  }

  /*region map actions*/
  private updateMapBounds() {
    if (!this.showMap) {
      return;
    }
    const map = this.map['map'];
    if (!map) {
      return;
    }

    const markers = [];

    if (this.startPoint.marker.getPosition()?.lat()) {
      markers.push(this.startPoint.marker);
    }
    this.stops.forEach(it => markers.push(it.marker));

    if (this.endpoint.marker.getPosition()?.lat()) {
      markers.push(this.endpoint.marker);
    }

    markers.push(this.homePoint.marker);

    const bounds = new google.maps.LatLngBounds();

    markers.filter(it => it.getPosition()?.lat()).forEach(marker => {
      bounds.extend(marker.getPosition());
    });

    if (markers.length > 1) {
      map.fitBounds(bounds);
    } else if (markers.length === 1) {
      map.setCenter(markers[0].getPosition());
      map.setZoom(16);
    }
  }

  private updateMarkerLabels() {
    this.stops.forEach((stop, index) => {
      stop.marker.setOptions({label: {text: `${index + 1}`}});
    });
  }

  public showRoute(): void {
    if (!this.startPoint.address || !this.startPoint.address.addressFormatted) {
      this.notificationService.error('Start address is not specified');
      return;
    }

    if (!this.endpoint.address || !this.endpoint.address.addressFormatted) {
      this.notificationService.error('End address is not specified');
      return;
    }

    this.showMap = true;
    Promise.all(this.requestRoute())
      .then(() => this.drawMap())
      .catch(() => {
      });
  }

  private requestRoute(): Promise<void>[] {
    const directions = this.stops
      .map(address => address.address);
    if (directions.length === 0 || this.startPoint.address === null) {
      this.notificationService.error('Please check address and selected technician');
      return;
    }
    this.resetMap();
    return this.stops
      .filter(stop => stop.address.addressFormatted !== undefined)
      .map(stop => {
        if (stop.address.lat === undefined || stop.address.lat === null || stop.address.lng === undefined || stop.address.lng === null) {
          return this.mapService.findAddressLatLng(stop.address.addressFormatted)
            .then(it => {
              stop.address.lat = it.lat;
              stop.address.lng = it.lng;
              stop.marker.setOptions({position: {...it}});
            });
        } else {
          stop.marker.setOptions({position: new google.maps.LatLng(stop.address.lat, stop.address.lng)});
        }
      });
  }

  private resetMap() {
    this.directionsRenderer.setMap(null);
  }

  private drawMap(): Promise<any> {
    this.showMap = true;
    this.stops.forEach(it => this.markers.push(it.marker));
    this.startPoint.marker.setOptions({position: {...this.startPoint.address}});
    this.endpoint.marker.setOptions({position: {...this.endpoint.address}});

    this.markers.push(this.startPoint.marker, this.endpoint.marker, this.homePoint.marker);

    const locations: google.maps.DirectionsWaypoint[] = this.stops
      .filter(it => it.address.lat !== undefined && it.address.lng !== undefined)
      .map(it => {
        return {
          location: {
            lat: it.address.lat,
            lng: it.address.lng
          },
          stopover: true,
        } as google.maps.DirectionsWaypoint;
      });

    return this.directionsService.route(
      {
        origin: this.startPoint.address.addressFormatted,
        destination: this.endpoint.address.addressFormatted,
        waypoints: locations,
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: this.routeShouldBeOptimized(),
        avoidTolls: this.routeShouldAvoidTolls(),
        avoidHighways: this.routeShouldAvoidHighways(),
        avoidFerries: this.routeShouldAvoidFerries(),
      }, (response: google.maps.DirectionsResult, status: any) => {
        this.disableRouteOptimization();
        if (status === 'OK') {
          this.legs = response.routes[0].legs;
          this.routeDetails = this.routeMetricsService.computeTotalDistanceAndDuration(this.legs, this.stops);
          this.directionsRenderer.setOptions({
            map: this.map['map'],
            panel: document.getElementById('panel') as HTMLElement,
            suppressMarkers: true,
            directions: response,
          });
          this.reorderStopsByWaypointOrder(response.routes[0].waypoint_order);
          setTimeout(_ => this.updateMapBounds(), 1000);
        } else {
          this.handleRouteRequestError(status);
        }
      }
    );
  }

  private reorderStopsByWaypointOrder(waypointOrder: number[]): void {
    const currentOrder = this.stops.map((_, index) => index);
    const isUnchanged = waypointOrder.every((value, index) => value === currentOrder[index]);

    if (isUnchanged) {
      return;
    }

    // Reorder stops
    const reorderedStops = waypointOrder.map(index => this.stops[index]);
    this.stops = [...reorderedStops];

    // Reorder form controls
    const stopsFormArray = this.baseGroup.get('stops') as UntypedFormArray;
    const reorderedControls = waypointOrder.map(index => stopsFormArray.at(index));
    stopsFormArray.clear();
    reorderedControls.forEach(control => stopsFormArray.push(control));

    // Update marker labels
    this.updateMarkerLabels();
  }

  /*endregion*/

  /*region metrics*/
  getDuration(index: number): number {
    const duration = this.legs[index].duration.value;
    let seconds = 0;
    if (index > 0 && this.stops[index - 1]) {
      seconds = this.stops[index - 1].timeNeeded * 3600;
    }
    return duration + seconds;
  }

  getDistance(index: number): number {
    return this.legs[index].distance.value;
  }

  getServiceDateTime(index: number): string {
    const stopsControls = this.getStopsControls();
    const stopsControl = stopsControls[index];
    const serviceTimeStartValue = stopsControl.get('serviceTimeStart').getRawValue();
    const serviceTimeEndValue = stopsControl.get('serviceTimeEnd').getRawValue();
    const timeNeededValue = stopsControl.get('timeNeeded').getRawValue();

    const startDateTime = this.datePipe.transform(serviceTimeStartValue, this.config.dateTimeOnly) || '';
    const endDateTime = this.datePipe.transform(serviceTimeEndValue, this.config.dateTimeOnly) || '';
    const timeNeeded = timeNeededValue ? `Needed ${timeNeededValue} hour` : '';
    const dateDivider = serviceTimeStartValue || serviceTimeEndValue ? '-' : '';
    const timeNeededDivider = serviceTimeStartValue || serviceTimeEndValue ? '/' : '';

    return `${startDateTime} ${dateDivider} ${endDateTime} ${timeNeededDivider} ${timeNeeded}`;
  }

  getTicketId(index: number): string {
    const stopsControls = this.getStopsControls();
    const stopsControl = stopsControls[index];
    return stopsControl.get('ticketId').getRawValue();
  }

  getTotalDuration(): number {
    return this.routeMetricsService.computeTotalDistanceAndDuration(this.legs, this.stops)
      .duration;
  }

  getTotalDistance(): number {
    return this.routeMetricsService.computeTotalDistanceAndDuration(this.legs, this.stops)
      .distance;
  }

  /*endregion*/

  /*region actions*/
  private openForRoute() {
    const technicianId = this.route.technicianId;
    this.updateTechAddress(technicianId);
    this.selectedTechnician = this.config.getTechnicianDataById(technicianId);
    this.formGroup.patchValue({
      technicianId,
      date: this.route.date,
      email: this.route.email,
    });
    const stops = this.route.stops;

    this.startPoint.id = this.route.startStop.id;
    this.startPoint.address = this.route.startStop.address;

    this.endpoint.id = this.route.endStop.id;
    this.endpoint.address = this.route.endStop.address;
    this.roundTrip = this.route.roundTrip;

    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      const addressDetails = stop.address;
      const addressedMarker = new AddressedMarker(addressDetails, new google.maps.Marker({
          position: new google.maps.LatLng(addressDetails.lat, addressDetails.lng),
          label: {text: '' + (i + 1)}
        })
      );
      addressedMarker.id = stop.id;
      addressedMarker.customer = stop.customer;
      addressedMarker.serviceTimeStart = stop.ticketServiceTimeStart;
      addressedMarker.serviceTimeEnd = stop.ticketServiceTimeEnd;
      addressedMarker.timeNeeded = stop.ticketTimeNeeded;
      addressedMarker.ticketId = stop.ticketId;
      addressedMarker.asStart = stop.asStart;
      addressedMarker.asEnd = stop.asEnd;
      this.stops.push(addressedMarker);
    }
    this.prepareBaseForm();
    this.setTechAddress(this.selectedTechnician?.address?.addressFormatted);
  }

  openServiceDateEditDialog(addressMarker: AddressedMarker) {
    this.mediaDialogRef = this.dialogService.open(TicketEditServiceTimeModalComponent, {
      header: `Edit service date / time`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        serviceTimeStart: addressMarker.serviceTimeStart,
        serviceTimeEnd: addressMarker.serviceTimeEnd,
        timeNeeded: addressMarker.timeNeeded,
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined));
  }

  removeStop(index: number) {
    const stopsFormArray: UntypedFormArray = this.baseGroup.get('stops') as UntypedFormArray;
    if (stopsFormArray.controls.length === 1) {
      this.notificationService.warning('At least one stop should be defined');
      return;
    }
    stopsFormArray.removeAt(index);
    this.markers.splice(index, 1);
    this.stops.splice(index, 1);
    this.updateMarkerLabels();
  }

  showLeg(legIndex: number) {
    this.selectedLeg = this.legs[legIndex];
    this.visibleLegSidebar = true;
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.copied();
    } else {
      this.notificationService.copyFailed();
    }
  }

  openMap(i: number) {
    const url = 'https://maps.google.com/maps?q=' + this.stops[i].address.addressFormatted;
    window.open(url, '_blank');
  }

  mergeNewTickets() {
    const newStops: StopItemModel[] = this.route.newStops;
    for (let i = 0; i < newStops.length; i++) {
      const newIndex = this.stops.length + 1;
      const newAddressedMarker = new AddressedMarker(new AddressDetails(), new google.maps.Marker({
          title: null,
          label: {text: '' + newIndex}
        })
      );
      newAddressedMarker.ticketId = newStops[i].ticketId;
      newAddressedMarker.serviceTimeStart = newStops[i].ticketServiceTimeStart;
      newAddressedMarker.serviceTimeEnd = newStops[i].ticketServiceTimeEnd;
      newAddressedMarker.timeNeeded = newStops[i].ticketTimeNeeded;
      newAddressedMarker.customer = newStops[i].customer;
      newAddressedMarker.address = {
        id: undefined,
        getFormattedAddress: newStops[i].address.getFormattedAddress, ...newStops[i].address
      };
      this.stops.push(newAddressedMarker);

      const stopsFormArray = this.baseGroup.controls['stops'] as UntypedFormArray;
      console.log('newAddressedMarker', newAddressedMarker);
      stopsFormArray.push(this.generateStopFormGroup(newAddressedMarker));
    }
    this.route.newStops = [];
    this.prepareBaseForm();
  }

  /*endregion*/

  /*region API calls*/
  public onSaveAndSend() {
    this.confirmRouteFinalized()
      .then(() => this.saveAndSend())
      .catch(reason => {
      });
  }

  public onSave() {
    Promise.all(this.requestRoute())
      .then(value => this.drawMap()
        .then(_ => this.save(false)));
  }

  public onSend() {
    this.confirmRouteFinalized()
      .then(() => this.send())
      .catch(reason => {
      });
  }

  public saveAndSend() {
    Promise.all(this.requestRoute())
      .then(value => this.drawMap()
        .then(_ => this.save(true)));
  }

  public send() {
    const email = this.formGroup.get('email').getRawValue();
    Promise.all(this.requestRoute())
      .then(value => this.drawMap()
        .then(_ => {
          this.sendRoute(this.route.id, email);
        }));
  }

  private sendRoute(routeId: number, email: string) {
    this.apiService.routeApi.sendRoute(routeId, email)
      .subscribe(value => {
        this.notificationService.success('Route has been sent');
      }, error => {
        this.notificationService.error('Something went wrong: route can not be sent');
      });
  }

  editServiceDates(index: number) {
    const addressMarker: AddressedMarker = this.stops[index];
    this.openServiceDateEditDialog(addressMarker)
      .subscribe(data => {
        const ticketId = addressMarker.ticketId;
        const serviceTimeStart = data.serviceTimeStart;
        const serviceTimeEnd = data.serviceTimeEnd;
        const timeNeeded = data.timeNeeded;
        const notifyCustomer = data.notifyCustomer;
        this.apiService.ticket.updateDates(ticketId, serviceTimeStart, serviceTimeEnd, timeNeeded, notifyCustomer)
          .subscribe(value => {
            addressMarker.timeNeeded = timeNeeded;
            addressMarker.serviceTimeStart = serviceTimeStart;
            addressMarker.serviceTimeEnd = serviceTimeEnd;
            const stopsControls = this.getStopsControls();
            stopsControls[index].get('serviceTimeStart').setValue(serviceTimeStart);
            stopsControls[index].get('serviceTimeEnd').setValue(serviceTimeEnd);
            this.notificationService.success('Ticket successfully updated');
          });
      });
  }

  private getTechnicianTodayRoute() {
    const date = dayjs().utc().hour(13).minute(0).second(0).millisecond(0).toISOString();
    this.apiService.routeApi.getRouteByDate(date)
      .subscribe(value => {
        if ((value && !this.route) || (value && this.route && this.route.id !== value.id)) {
          this.currentDateRouteId = value.id;
          this.confirmationService.confirm({
            message: 'You have a planned route for today',
            acceptLabel: 'Open',
            rejectLabel: 'Close',
            accept: () => {
              this.router.navigate(['/routes', value.id]);
            }
          });
        }
      });
  }

  private loadTechnicianAddress(technicianId: number) {
    this.apiService.technician.getAddress(technicianId)
      .subscribe((addressDetails: AddressDetails) => {
        this.mapService.findAddressLatLng(addressDetails.addressFormatted)
          .then(it => {
            addressDetails.lng = it.lng;
            addressDetails.lat = it.lat;
            this.startAddressChange({address: addressDetails, valid: true});
            this.endAddressChange({address: addressDetails, valid: true});
            this.techHomeAddressChange({address: addressDetails, valid: true});
          });
      });
  }

  private updateTechAddress(technicianId: number) {
    this.apiService.technician.getAddress(technicianId)
      .subscribe((addressDetails: AddressDetails) => {
        this.mapService.findAddressLatLng(addressDetails.addressFormatted)
          .then(it => {
            addressDetails.lng = it.lng;
            addressDetails.lat = it.lat;
            this.techHomeAddressChange({address: addressDetails, valid: true});
          });
      });
  }

  private save(notify: boolean): void {
    const date = this.formGroup.get('date').getRawValue() as Date;
    const email = this.formGroup.get('email').getRawValue();
    const roundTrip = this.isRoundTrip();
    if (!date) {
      this.notificationService.warning('Date was not specified');
      return;
    }
    if (!email) {
      this.notificationService.warning('Email was not specified');
      return;
    }
    // const control = this.getStopsControls();
    const stops = this.baseGroup.get('stops').getRawValue();
    this.stops.forEach((stop, index) => stops[index].address = {...stop.address});

    this.routeService.save(
      this.route?.id,
      date,
      email,
      stops,
      this.startPoint,
      this.endpoint,
      roundTrip,
      this.legs,
      this.selectedTechnician,
      notify
    );
  }
  /*endregion*/

  private handleRouteRequestError(status: any) {
    switch (status) {
      case 'ZERO_RESULTS':
        this.notificationService.warning('No route could be found between the start and end addresses');
        return;
      case 'MAX_WAYPOINTS_EXCEEDED':
        this.notificationService.error('Too many stops were provided');
        return;
      case 'MAX_ROUTE_LENGTH_EXCEEDED':
        this.notificationService.error('Requested route is too long and cannot be processed');
        return;
      case 'NOT_FOUND':
      case 'INVALID_REQUEST':
        this.notificationService.error('At least one of the stops is invalid');
        return;
      case 'OVER_QUERY_LIMIT':
      case 'REQUEST_DENIED':
      case 'UNKNOWN_ERROR':
        console.error(status);
        this.notificationService.error('Request could not be processed due to a server error. The request may succeed if you try again.\n');
        return;
    }
  }

  /*region confirmations*/
  private confirmRouteFinalized() {
    return new Promise<void>((resolve, reject) => {
      this.confirmationService.confirm({
        header: 'Confirm route sending',
        message: 'Route finalized and ready?',
        acceptLabel: 'Send',
        rejectLabel: 'Cancel',
        closeOnEscape: true,
        dismissableMask: false,
        accept: () => resolve(),
        reject: () => reject(),
      });
    });
  }

  /*endregion*/
  setAsStart(checked: boolean, index: number) {
    this.setRoundTrip(false);
    const stopsControls = this.getStopsControls();

    if (stopsControls[index].get('asEnd').getRawValue()) {
      this.checkHomeAsEnd();
      this.setTechAddressAsEnd();
    }

    const allAreUnchecked = stopsControls.map(it => it.get('asStart').getRawValue() as boolean)
      .every(it => !it);

    if (allAreUnchecked) {
      this.startAddressChange({address: this.selectedTechnician.address, valid: true});
      this.checkHomeAsStart();
      return;
    }

    this.uncheckStopsAsStart();
    this.uncheckHomeAsStart();

    stopsControls[index].get('asStart').setValue(true);
    stopsControls[index].get('asEnd').setValue(false);

    this.startAddressChange({address: this.stops[index].address, valid: true});
    moveItemInArray(stopsControls, index, 0);
    moveItemInArray(this.stops, index, 0);
    this.updateMarkerLabels();
  }

  setAsEnd(checked: boolean, index: number) {
    this.setRoundTrip(false);

    const stopsControls = this.getStopsControls();

    if (stopsControls[index].get('asStart').getRawValue()) {
      this.checkHomeAsStart();
      this.setTechAddressAsStart();
    }

    const allAreUnchecked = stopsControls.map(it => it.get('asEnd').getRawValue() as boolean)
      .every(it => !it);

    if (allAreUnchecked) {
      this.endAddressChange({address: this.selectedTechnician.address, valid: true});
      this.checkHomeAsEnd();
      return;
    }

    this.uncheckStopsAsEnd();
    this.uncheckHomeAsEnd();

    stopsControls[index].get('asStart').setValue(false);
    stopsControls[index].get('asEnd').setValue(true);

    this.endAddressChange({address: this.stops[index].address, valid: true});
    moveItemInArray(this.getStopsControls(), index, this.stops.length - 1);
    moveItemInArray(this.stops, index, this.stops.length - 1);
    this.updateMarkerLabels();
  }

  setTechAddressAsStart() {
    this.setRoundTrip(false);
    this.startAddressChange({address: this.selectedTechnician?.address, valid: true});
    this.updateMarkerLabels();
    this.uncheckStopsAsStart();
  }

  setTechAddressAsEnd() {
    this.setRoundTrip(false);
    this.endAddressChange({address: this.selectedTechnician?.address, valid: true});
    this.updateMarkerLabels();
    this.uncheckStopsAsEnd();
  }

  private uncheckStopsAsStart() {
    const stopsControls = this.getStopsControls();
    for (let stopsControl of stopsControls) {
      stopsControl.get('asStart').setValue(false);
    }
  }

  private uncheckStopsAsEnd() {
    const stopsControls = this.getStopsControls();
    for (let stopsControl of stopsControls) {
      stopsControl.get('asEnd').setValue(false);
    }
  }

  private disableEndAddress() {
    this.baseGroup.get('endAddress').disable();
  }

  private enableEndAddress() {
    this.baseGroup.get('endAddress').enable();
  }

  private checkHomeAsStart() {
    this.baseGroup.get('asStart').setValue(true);
  }

  private checkHomeAsEnd() {
    this.baseGroup.get('asEnd').setValue(true);
  }

  private uncheckHomeAsStart() {
    this.baseGroup.get('asStart').setValue(false);
  }

  private uncheckHomeAsEnd() {
    this.baseGroup.get('asEnd').setValue(false);
  }

  private setRoundTrip(value: boolean) {
    this.baseGroup.get('roundTrip').setValue(value);
  }

  private setTechAddress(address: string) {
    this.baseGroup.get('address').setValue(address);
  }

  private setTechEmail(email: string) {
    this.formGroup.get('email').setValue(email);
  }

  private isRoundTrip(): boolean {
    return this.baseGroup.get('roundTrip').getRawValue() as boolean;
  }

  private routeShouldBeOptimized() {
    return this.optionsForm.get('optimizeRoute').getRawValue() as boolean;
  }

  private routeShouldAvoidHighways() {
    return this.optionsForm.get('avoidHighways').getRawValue() as boolean;
  }

  private routeShouldAvoidFerries() {
    return this.optionsForm.get('avoidFerries').getRawValue() as boolean;
  }

  private routeShouldAvoidTolls() {
    return this.optionsForm.get('avoidTolls').getRawValue() as boolean;
  }

  private disableRouteOptimization() {
    this.optionsForm.get('optimizeRoute').setValue(false);
  }

}
