<div class="flex flex-wrap">
  <div *ngFor="let filter of payFormSearchFilter | keys" class="mr-2 mb-2">
    <ng-container [ngSwitch]="filter.key">
      <p-chip *ngSwitchCase="'startDate'" [removable]="true" (onRemove)="onRemoveFilter(filter.key)"
              label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip *ngSwitchCase="'endDate'" [removable]="true" (onRemove)="onRemoveFilter(filter.key)"
              label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip *ngSwitchCase="'deleted'" [removable]="true" (onRemove)="onRemoveFilter(filter.key)"
              label="Deleted"></p-chip>
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchDefault
              label="{{filterNames[filter.key]}}: {{filter.value}}"></p-chip>
      <p-chip *ngSwitchCase="'technicianId'" [removable]="true" (onRemove)="onRemoveFilter(filter.key)"
              label="{{filterNames[filter.key]}}: {{techniciansMap[filter.value].name}} {{techniciansMap[filter.value].companyName ? ' / ' + techniciansMap[filter.value].companyName : ''}}"></p-chip>

    </ng-container>
  </div>
</div>
