import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BaseFormGroup} from '../../../../shared/utils/base-form-group';
import {InvoiceSearchFilterModel} from '../../../models/invoice-search-filter.model';
import {ConfigService} from '../../../../../core/services';
import {DropdownModel} from '../../../../../api/model/shared/dropdown.model';
import {BehaviorSubject} from 'rxjs';
import {NettermTypeModel} from '../../../../../api/model/Netterm.model';

@Component({
  selector: 'tj-invoice-search-form',
  templateUrl: './invoice-search-form.component.html',
  styleUrls: ['./invoice-search-form.component.scss']
})
export class InvoiceSearchFormComponent extends BaseFormGroup implements OnInit, OnChanges {

  public readonly nettermsTypes$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<DropdownModel[]>([]);

  @Input() filter: InvoiceSearchFilterModel;

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  constructor(public configService: ConfigService,
              private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.getNettermTypes();
    const filter = this.filter || {} as InvoiceSearchFilterModel;
    const formControls = {
      id: this.fb.control(filter.id || ''),
      ticketId: this.fb.control(filter.ticketId || ''),
      status: this.fb.control(filter.status),
      customer: this.fb.control(filter.customer || ''),
      comment: this.fb.control(filter.comment || ''),
      partner: this.fb.control(filter.partner || ''),
      address: this.fb.control(filter.address || ''),
      technician: this.fb.control(filter.technician || ''),
      serviceDate: this.fb.control(filter.serviceDate || ''),
      issueDate: this.fb.control(filter.issueDate || ''),
      netTermType: this.fb.control(filter.issueDate || ''),
      contact: this.fb.control(filter.contact || ''),
      partnerId: this.fb.control(filter.partnerId || ''),
      privatePartner: this.fb.control(filter.privatePartner || ''),
      partnerType: this.fb.control(filter.partnerType || ''),
      invoiceAmount: this.fb.control(filter.invoiceAmount || ''),
      externalReferenceCode: this.fb.control(filter.externalReferenceCode || ''),
    };

    this.buildForm(this.form, formControls, this.group);
    this.defineGetters(formControls);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue === null) {
      this.filter = {} as InvoiceSearchFilterModel;
    }
  }

  private getNettermTypes(): void {
    this.configService.nettermsTypes$.subscribe((nettermModels: NettermTypeModel[]) => {
      const nettermsTypes: DropdownModel[] = [];
      nettermsTypes.push({value: undefined, label: 'Not set'});
      nettermModels.forEach((nettermModel: NettermTypeModel) => {
        nettermsTypes.push({value: nettermModel.netTermType, label: nettermModel.name});
      });
      this.nettermsTypes$.next(nettermsTypes);
    });
  }

}
