import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PayFormEditModel, PayFormItemEditModel, PayFormItemTicketEditModel} from '../../../models';
import {FormHelpers} from '../../../../shared/utils/form-helpers';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../../core/services';
import { TechnicianMinimalListItem } from '../../../../../api/model/TechnicianMinimalListItem';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as dayOfYear from 'dayjs/plugin/dayOfYear';
import {pairwise} from 'rxjs/operators';
import {PayType} from '../../../../shared/enums/pay.type';
import {PayFormUpdateCheckService} from '../../../../ticket/services/payform-update-check.service';
import {Messages} from '../../../../../common/messages';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

dayjs.extend(utc);
dayjs.extend(dayOfYear);

@Component({
  providers: [PayFormUpdateCheckService],
  selector: 'tj-pay-form-edit-form',
  templateUrl: './pay-form-edit-form.component.html',
  styleUrls: ['./pay-form-edit-form.component.scss']
})
export class PayFormEditFormComponent implements OnInit, OnChanges {

  private _payForm: PayFormEditModel;

  @Output() onTransfer: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPayInFull: EventEmitter<any> = new EventEmitter<any>();

  @Input() form: UntypedFormGroup;

  @Input() isSaving = false;
  disableTransferButton = false;

  get payForm(): PayFormEditModel {
    return this._payForm;
  }

  @Input() set payForm(value: PayFormEditModel) {
    // tslint:disable-next-line:no-shadowed-variable
    value.payFormItems.sort((p1, p2) => {
      const p1Milliseconds: number = +new Date(p1.date);
      const p2Milliseconds: number = +new Date(p2.date);
      return p1Milliseconds - p2Milliseconds < 0 ? -1 : 1;
    });
    this._payForm = value;
  }

  @Output() createEvent: EventEmitter<PayFormEditModel> = new EventEmitter<PayFormEditModel>();

  days: string[] = [];
  routerTicketId: number | null = null;

  readonly = false;

  constructor(public configService: ConfigService,
              public keycloakService: TjKeycloakService,
              public payFormUpdateCheckService: PayFormUpdateCheckService,
              private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private confirmationService: ConfirmationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.initForm();
    this.checkPermission();
    this.updateForm();
  }

  ngOnInit(): void {
    this.initPayFormUpdateCheckService();
    this.route.queryParamMap.subscribe(params => {
      this.routerTicketId = +params.get('ticketId')!;
    });
  }

  private initForm(): void {
    this.form.addControl('percent', this.fb.control(this.payForm.percent, [Validators.required]));
    this.form.addControl('technicianId', this.fb.control(this.payForm.technicianId, [Validators.required]));
    this.form.addControl('startDate', this.fb.control(this.payForm.startDate, [Validators.required]));
    this.form.addControl('endDate', this.fb.control(this.payForm.endDate, [Validators.required]));
    this.form.addControl('status', this.fb.control(this.payForm.status));
    this.form.addControl('balance', this.fb.control(this.payForm.balance));
    this.form.addControl('payBalance', this.fb.control(this.payForm.payBalance));
    this.form.addControl('cashTotal', this.fb.control(this.payForm.cashTotal));
    this.form.addControl('inCome', this.fb.control(this.payForm.inCome));
    if (this.form.contains('payFormItems')) {
      this.form.removeControl('payFormItems');
    }
    this.form.addControl('payFormItems', this.fb.array(this.createPayFormItems(this.payForm.payFormItems)));
    this.getDaysBetWeenStartAndEndDate();
    this.addTotalListeners();
    this.addBalanceListener();
    this.addTechGrossListener();
    this.addPayBalanceListener();
  }

  createTicket(index: number, model: UntypedFormGroup): void {
    const ticketsFormArray = model.controls['payFormItemTickets'] as UntypedFormArray;
    const newTicket: PayFormItemTicketEditModel = this.createNewTicket();
    ticketsFormArray.insert(0, this.ticketToForm(newTicket));
  }

  private createPayFormItems(payFormItemEditModels: PayFormItemEditModel[]) {
    if (!payFormItemEditModels) {
      return [];
    }
    return payFormItemEditModels.map((payFormItem: PayFormItemEditModel) => {
      return this.createPayFormItem(payFormItem);
    });
  }

  public initPayFormUpdateCheckService() {
    this.payFormUpdateCheckService.initPayFormUpdateTracking(this.payForm)
      .subscribe(() => {
        this.showTicketHasBeenChangedDialog();
        this.disableSaving();
      });
  }

  private showTicketHasBeenChangedDialog() {
    this.notificationService.warning(Messages.PAY_FORM_HAS_BEEN_CHANGED);
  }

  private disableSaving() {
    this.readonly = true;
  }

  private createPayFormItem(payFormItem: PayFormItemEditModel) {
    return this.model(payFormItem);
  }

  private model(model: PayFormItemEditModel): UntypedFormGroup {
    model.area = this.getAreaCodes(model);
    return this.fb.group({
      id: this.fb.control({ value: model.id }),
      date: this.fb.control({ value: model.date }),
      area: this.fb.control({ value: model.area }),
      totalIncomeCash: this.fb.control({value: model.totalIncomeCash, disabled: true}),
      totalIncomeCheck: this.fb.control({value: model.totalIncomeCheck, disabled: true}),
      totalExpense: this.fb.control({value: model.totalExpense, disabled: true}),
      totalTechnicianExpense: this.fb.control({value: model.totalTechnicianExpense, disabled: true}),
      technicianTotalIncomeCash: this.fb.control({value: model.technicianTotalIncomeCash, disabled: true}),
      technicianTotalIncomeCheck: this.fb.control({value: model.technicianTotalIncomeCheck, disabled: true}),
      technicianTotalExpense: this.fb.control({value: model.technicianTotalExpense, disabled: true}),
      technicianTotalTechnicianExpense: this.fb.control({value: model.technicianTotalTechnicianExpense, disabled: true}),
      payOff: this.fb.control({value: model.payOff, disabled: true}),
      incomeDayTotal: this.fb.control({value: model.incomeDayTotal}),
      payFormItemTickets: this.fb.array(this.createPayFormItemJob(model))
    });
  }

  getAreaCodes(model: PayFormItemEditModel): string {
    const uniqueAreaCodes = Array.from(
      new Set(
        model.payFormItemTickets
          .flatMap(ticket => ticket.areas?.map(area => area.code))
      )
    );
    return uniqueAreaCodes.join(', ');
  }

  private createPayFormItemJob(model: PayFormItemEditModel) {
    if (!model.payFormItemTickets) {
      return [];
    }
    return model.payFormItemTickets.map(payFormItemTicket => this.ticketToForm(payFormItemTicket));
  }

  private ticketToForm(ticket: PayFormItemTicketEditModel): UntypedFormGroup {
    const thegroup = this.fb.group({
      id: this.fb.control(ticket.id),
      ticketId: this.fb.control(ticket.ticketId),
      note: this.fb.control(ticket.note),
      incomeCash: this.fb.control(ticket.incomeCash),
      incomeCheck: this.fb.control(ticket.incomeCheck),
      expenses: this.fb.control(ticket.expenses),
      technicianExpenses: this.fb.control(ticket.technicianExpenses),
    });
    return thegroup;
  }

  private addTotalListeners(): void {
    const modelsFormArray = this.form.get('payFormItems') as UntypedFormArray;
    modelsFormArray.controls.forEach((formGroup: UntypedFormGroup) => {
      const percent = this.payForm.percent;
      const jobsFormArray: UntypedFormArray = formGroup.get('payFormItemTickets') as UntypedFormArray;
      const totalIncomeCashFormControl: UntypedFormControl = formGroup.get('totalIncomeCash') as UntypedFormControl;
      const totalIncomeCheckFormControl: UntypedFormControl = formGroup.get('totalIncomeCheck') as UntypedFormControl;
      const totalExpenseFormControl: UntypedFormControl = formGroup.get('totalExpense') as UntypedFormControl;
      const totalTechnicianExpenseFormControl: UntypedFormControl = formGroup.get('totalTechnicianExpense') as UntypedFormControl;
      const technicianTotalIncomeCashFormControl: UntypedFormControl = formGroup.get('technicianTotalIncomeCash') as UntypedFormControl;
      const technicianTotalIncomeCheckFormControl: UntypedFormControl = formGroup.get('technicianTotalIncomeCheck') as UntypedFormControl;
      const technicianTotalExpenseFormControl: UntypedFormControl = formGroup.get('technicianTotalExpense') as UntypedFormControl;
      const technicianTotalTechnicianExpenseFormControl: UntypedFormControl = formGroup.get('technicianTotalTechnicianExpense') as UntypedFormControl;
      const payOffFormControl: UntypedFormControl = formGroup.get('payOff') as UntypedFormControl;
      const incomeDayTotalFormControl: UntypedFormControl = formGroup.get('incomeDayTotal') as UntypedFormControl;
      jobsFormArray.valueChanges.subscribe((value: []) => {
        let totalIncomeCash = 0;
        let totalIncomeCheck = 0;
        let totalExpenses = 0;
        let totalTechnicianExpenses = 0;
        let payOff = 0;
        let incomeDayTotal = 0;
        for (const job of value) {
          // tslint:disable-next-line:no-shadowed-variable
          const cash = isNaN(job['incomeCash']) ? 0 : +job['incomeCash'];
          // tslint:disable-next-line:no-shadowed-variable
          const check = isNaN(job['incomeCheck']) ? 0 : +job['incomeCheck'];
          // tslint:disable-next-line:no-shadowed-variable
          const expenses = isNaN(job['expenses']) ? 0 : +job['expenses'];
          // tslint:disable-next-line:no-shadowed-variable
          const technicianExpenses = isNaN(job['technicianExpenses']) ? 0 : +job['technicianExpenses'];

          totalIncomeCash += cash;
          totalIncomeCheck += check;
          totalExpenses += expenses;
          totalTechnicianExpenses += technicianExpenses;
        }
        incomeDayTotal = totalIncomeCash + totalIncomeCheck - totalExpenses - totalTechnicianExpenses;
        const cash: number = +totalIncomeCash;
        const check: number = +totalIncomeCheck;
        const expenses: number = +totalExpenses;
        const technicianExpenses: number = +totalTechnicianExpenses;

        const technicianTotalIncomeCash = cash * percent / 100;
        const technicianTotalIncomeCheck = (check - totalExpenses - totalTechnicianExpenses) * percent / 100;

        const profitWithoutExpenses = cash + check - expenses - technicianExpenses;
        const technicianProfit = profitWithoutExpenses * percent / 100;
        payOff += technicianProfit - (cash - technicianExpenses);
        /*total day*/
        totalIncomeCashFormControl.setValue(cash.toFixed(2));
        totalIncomeCheckFormControl.setValue(check.toFixed(2));
        totalTechnicianExpenseFormControl.setValue(technicianExpenses.toFixed(2));
        totalExpenseFormControl.setValue(expenses.toFixed(2));
        payOffFormControl.setValue(payOff.toFixed(2));
        incomeDayTotalFormControl.setValue(incomeDayTotal);
        /*total technician*/
        technicianTotalIncomeCashFormControl.setValue((technicianTotalIncomeCash).toFixed(2));
        technicianTotalIncomeCheckFormControl.setValue((technicianTotalIncomeCheck).toFixed(2));
        technicianTotalExpenseFormControl.setValue((totalExpenses).toFixed(2));
        technicianTotalTechnicianExpenseFormControl.setValue((totalTechnicianExpenses).toFixed(2));
      });
    });
  }

  private addBalanceListener(): void {
    const modelsFormArray = this.form.get('payFormItems') as UntypedFormArray;
    modelsFormArray.valueChanges.subscribe((values: any[]) => {
      const balance = values.reduce((sum: number, e: PayFormItemEditModel) => {
        const bal = e.payFormItemTickets.reduce((s: number, q: PayFormItemTicketEditModel) => {
          return s + (isNaN(q.incomeCash) ? 0 : +q.incomeCash) + (isNaN(q.incomeCheck) ? 0 : +q.incomeCheck);
        }, 0);
        sum += isNaN(bal) ? 0 : +bal;
        return sum;
      }, 0);
      this.form.get('balance').setValue(balance);
    });
  }

  private addTechGrossListener() {
    this.form.get('balance').valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev !== null && next !== null && prev !== next) {
          this.form.get('inCome').setValue(next * this.payForm.percent / 100);
        }
      });
  }

  private addPayBalanceListener() {
    this.form.get('payBalance').valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        const nextValue = next ? parseFloat(next) : null;
        if (nextValue === 0) {
          this.disableTransferButton = true;
          return;
        }
        this.disableTransferButton = false;
      });
  }

  update(): void {
    this.createEvent.emit();
  }

  createNewForm(): void {
    if (this.form.valid) {
      this.update();
    } else {
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  private getDaysBetWeenStartAndEndDate(): void {
    const startDate = this.payForm.startDate;
    const endDate = this.payForm.endDate;
    const startDateDayOfYear = dayjs(startDate).utc().dayOfYear();
    const endDateDayOfYear = dayjs(endDate).utc().dayOfYear();
    for (let day = startDateDayOfYear; day <= endDateDayOfYear; day++) {
      this.days.push(dayjs().utc().dayOfYear(day).format());
    }
  }

  private createNewTicket(): PayFormItemTicketEditModel {
    const model = new PayFormItemTicketEditModel();
    model.expenses = 0;
    model.incomeCash = 0;
    model.incomeCheck = 0;
    model.technicianExpenses = 0;
    return model;
  }

  private checkPermission() {
    this.form.reset(this.payForm);
    if (!this.keycloakService.hasRole('PAYFORM_EDIT')) {
      this.form.disable({onlySelf: false, emitEvent: false});
    }
  }

  private updateForm() {
    const fcNames: string[] = ['technicianId', 'startDate', 'endDate'];
    if (this.payForm.id) {
      fcNames.forEach(fcName => this.form.get(fcName).disable());
    } else {
      fcNames.forEach(fcName => this.form.get(fcName).enable());
    }
  }

  getTechnicianName(): string {
    if (!this.payForm.technicianId) {
      return '';
    }
    const technician = this.configService.technicians$
      .getValue()
      .find(a => a.id === this.payForm.technicianId);
    return technician?.name;
  }

  updatePercent(technician: TechnicianMinimalListItem): void {
    this.form.get('percent').setValue(technician.percent);
  }

  transfer() {
    const payBalance = this.form.get('payBalance').value as number;
    this.onTransfer.emit({
      payFormId: this.payForm.id,
      payBalance
    });
  }

  onEnterPress() {
    this.createEvent.emit();
  }

  isTechnicianHourBased(technicianId): boolean {
    const technician = this.configService.technicians$.getValue().find(t => t.id === technicianId);
    return technician && technician.payType && technician.payType === PayType.HOUR_BASED;
  }

  getTechnicianUnitValue(technicianId) {
    return this.configService.technicians$.getValue().find(technician => technician.id === technicianId)?.unit;
  }

  payInFull() {
    const date = new Date();
    let paidAmount = this.form.controls['info'].get('paidAmount').value as number || 0;
    const payBalance = this.form.get('payBalance').value as number || 0;
    paidAmount = Number(paidAmount) + Number(payBalance);
    this.form.controls['info'].get('paidAmount').setValue(paidAmount);
    this.form.controls['info'].get('paidDate').setValue(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0, 0, 0)));
    this.form.get('payBalance').setValue(0);
    this.form.get('status').setValue('CLOSED');
    this.onPayInFull.emit();
  }

  canViewPayInFull(): boolean {
    return this.keycloakService.hasRole('PAYFORM_VIEW_PAY_IN_FULL');
  }

  selectText(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }

  deleteTicket(i: number, j: number) {
    const payFormItems = this.form.get('payFormItems') as UntypedFormArray;
    const payFormItem = payFormItems.at(i);
    const payFormItemTickets = payFormItem.get('payFormItemTickets') as UntypedFormArray;
    payFormItemTickets.removeAt(j);
  }


  confirmDelete(i: number, j: number): void {
    // Unique key to prevent multiple modals from opening.
    // Each ticket has a separate key to open its corresponding dialog.
    const uniqueTicketKey = `confirmDeletion-${i}-${j}`;

    this.confirmationService.confirm({
      message: 'Delete this payment?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: uniqueTicketKey,

      accept: () => {
        this.deleteTicket(i, j);
      }
    });
  }

}
