import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TjImageOverlayComponent} from '../tj-image-overlay/tj-image-overlay.component';
import {TjImageCropperComponent} from '../tj-image-croper/tj-image-cropper.component';
import {GalleryService} from '../../../shared/services';
import {ImageRequestModel} from '../../../shared/models/ImageRequest.model';
import {environment} from '../../../../../environments/environment';
import {toDataURL} from '../../../../api/model/shared/functions';
import {ApiService} from '../../../../api/service/api.service';
import {TicketEditContextService} from '../../../ticket/services/ticket-edit-context.service';
import {LayoutService} from '../../../../layout/service/app.layout.service';

@Component({
  selector: 'tj-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.scss']
})
export class ImageEditComponent implements OnInit {

  @ViewChild('overlayComponent')
  overlayComponent: TjImageOverlayComponent;

  @ViewChild('cropperComponent')
  cropperComponent: TjImageCropperComponent;

  @Input() galleryId: number;

  @Input() ticketId: number;

  @Input() display = false;

  @Output() imageEditDone = new EventEmitter();


  imageFlow: ImageUploadingFlowType = 'SELECT';

  image: ImageRequestModel;

  index: number;
  mainImage;

  constructor(private galleryService: GalleryService,
              private context: TicketEditContextService,
              private layoutService: LayoutService,
              private api: ApiService) {
  }

  ngOnInit() {
  }

  public edit(attachment: ImageRequestModel, index: number) {
    this.display = true;
    this.image = attachment;
    this.index = index;
  }

  crop(img): void {
    this.mainImage = img;
    this.imageFlow = 'OVERLAY';
  }

  saveImage(): void {
    const savedImage = this.dataURLtoFile(this.overlayComponent.getImgBase64(), `img_${this.galleryId}_${this.image.id}`);
    this.api.gallery.edit(this.ticketId, this.galleryId, this.image.id, savedImage).subscribe(r => {
        const img = new ImageRequestModel(r.response.id);
        this.imageEditDone.emit(img.id);
        this.context.ticketGalleryEmmit.emit({id: img.id});
      }
    );
    this.display = false;
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }


  getImageUrl(): string {
    return this.galleryService.getImageUrl(this.image.cropPath, {width: 400, height: 400}, this.image.fileName, this.image.index);
  }

  getImageCroppedUrl(): string {
    return this.mainImage;
  }

  onHide(): void {
    this.image = null;
    this.imageFlow = 'SELECT';
  }

  onShow(): void {
    const isMobile = this.layoutService.isMobile();

    const width = isMobile ? window.innerWidth - 50 : 600;

    const url = this.galleryService.getImageUrl(this.image.cropPath, {width}, this.image.fileName, this.image.index);

    toDataURL(url)
      .then(dataUrl => {
        this.mainImage = dataUrl;
        this.imageFlow = 'OVERLAY';
      });
  }
}
