<div class="address-contact-dialog">
  <form [formGroup]="actionForm" (ngSubmit)="onSave()" disabled="{{readonly}}">

    <div class="form-group">
      <tj-field [control]="getControl('apt')" label="Address unit">
        <input pInputText formControlName="apt" placeholder="Address unit">
      </tj-field>
    </div>

    <div class="col-12 px-0 m-0">
      <hr>
      <span class="font-semibold" style="font-size: 18px">Additional address / notes: </span>
      <span>{{customer?.address?.additionalAddress || 'Not set'}}</span>
      <hr>
    </div>

    <div class="form-group my-2">
      <tj-field [control]="getControl('additionalAddress')" label="Additional address / notes">
        <textarea pInputText formControlName="additionalAddress" placeholder="Additional address / notes"
                  rows="2"></textarea>
      </tj-field>
    </div>

    <div formArrayName="contacts">
      <div class="col-fixed px-0 text-right">
        <button *ngIf="contacts.controls.length === 0" class="p-button-success" icon="pi pi-plus" pButton type="button"
                label="Add contact"
                (click)="addContact()"></button>
      </div>
      <div *ngFor="let contact of contacts.controls; let i = index, let last = last;" [formGroupName]="i"
           class="form-group">
        <div class="col-12 px-0">
          <div class="grid align-items-end">
            <div class="lg:col-4 col-8">
              <tj-field label="Contact" [control]="contact.controls.type">
                <p-dropdown appendTo="body" [options]="configService.contactTypes" placeholder="Contact type"
                            formControlName="type"></p-dropdown>
              </tj-field>
            </div>
            <div class="lg:col-6 col-8">
              <tj-field label="Number/Email" [control]="contact.controls.value">
                <input pInputText [type]="contact.controls.type.value === 'EMAIL' ? 'email' : 'text'"
                       placeholder="Number/Email *" formControlName="value">
              </tj-field>
            </div>
            <div class="col-fixed">
              <button class="p-button-danger" icon="pi pi-trash" pButton type="button"
                      (click)="removeContact(i)"></button>
            </div>
            <div class="col-fixed">
              <button *ngIf="last" class="p-button-success" icon="pi pi-plus" pButton type="button"
                      (click)="addContact()"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-fixed px-0 text-right">
      <button pButton type="submit" [disabled]="actionForm.invalid" class="p-button">Save</button>
    </div>
  </form>
</div>
