<!--Route filter form-->
<p-panel header="Filters">
  <div [formGroup]="formGroup" class="grid">
    <div class="col-12 text-right">
      <a [routerLink]="['/routes']" [pTooltip]="'Show previous routes'">Saved routes</a>
    </div>
    <div class="lg:col-3 col-12" *ngIf="routesRestricted()">
      <tj-technician-combobox formControlName="technicianId" [disabled]="!!route"
                              (technicianChange)="selectTechnician($event)"></tj-technician-combobox>
    </div>
    <div class="lg:col-3 col-6">
      <tj-field>
        <tj-datepicker [onlyFuture]="false" [disabled]="!!route" formControlName="date"
                       placeholder="MM/DD/YY"></tj-datepicker>
      </tj-field>
    </div>
    <div class="lg:col-3 col-6">
      <input placeholder="Email" pInputText type="email" formControlName="email"/>
    </div>
    <div class="col flex justify-content-end">
      <div class="mr-1">
        <button (click)="onSave()" pButton class="p-button-success">Save</button>
      </div>
      <div class="mr-1">
        <button (click)="onSaveAndSend()" pButton>Save and Send</button>
      </div>
      <div class="" *ngIf="route">
        <button (click)="onSend()" pButton>Send</button>
      </div>
    </div>
  </div>
</p-panel>

<!--For technicians to show that they have a planned route for current day-->
<ng-container *tjHasRole="'TICKET_COMMENT_ON_TIME_NEEDED_EDIT'">
  <div class="col-12" *ngIf="!route?.id && currentDateRouteId">
    <p class="text-red-400">
      You have a planned route for today.
      <a [routerLink]="['/routes', currentDateRouteId]">Check here</a>
    </p>
  </div>
</ng-container>

<ng-container *ngIf="this.route?.newStops.length > 0">
  <div class="flex align-items-center justify-content-start mt-2">
    <span class="text-red-400 mr-2">New tickets are available for selected date</span>
    <button pButton (click)="mergeNewTickets()">Fetch new tickets</button>
  </div>
</ng-container>

<p-panel header="Route planner" styleClass="mt-2">
  <div class="grid">
    <div class="col-12 lg:col-6">
      <div class="grid" [formGroup]="optionsForm">
        <div class="lg:col-3 col-6">
          <p-checkbox binary="true"
                      formControlName="optimizeRoute"
                      pTooltip="Optimize route"></p-checkbox>
          <span class="ml-2">Optimize Route (AI)</span>
        </div>
        <div class="lg:col-3 col-6">
          <p-checkbox binary="true"
                      formControlName="avoidTolls"
                      pTooltip="Calculated route should avoid toll roads/bridges"></p-checkbox>
          <span class="ml-2">Avoid Tolls</span>
        </div>
        <div class="lg:col-3 col-6">
          <p-checkbox binary="true"
                      formControlName="avoidHighways"
                      pTooltip="Calculated route should avoid highways"></p-checkbox>
          <span class="ml-2">Avoid Highways</span>
        </div>
        <div class="lg:col-3 col-6">
          <p-checkbox binary="true"
                      formControlName="avoidHighways"
                      pTooltip="Calculated route should avoid ferries"></p-checkbox>
          <span class="ml-2">Avoid Ferries</span>
        </div>
      </div>

      <div class="col-12" *ngIf="routeDetails">
        <h4>Duration {{ getTotalDuration() | duration }} | Distance {{ getTotalDistance() | distance : "mi" }}</h4>
      </div>
      <div class="col-12" [formGroup]="baseGroup">
        <hr>
        <!--start address-->
        <div class="grid mb-3">
          <div class="col-fixed col-2">
            <img src="{{ORIGIN_ICON}}" alt="Start Address Flag"/>
            <p>Start / First</p>
          </div>
          <div class="col-fixed col-7">

            <div class="p-inputgroup">
              <input pInputText appGooglePlacesAutocomplete (addressSelected)="startAddressChange($event)"
                     formControlName="startAddress" placeholder="Enter an address or place">
              <button pButton icon="pi pi-copy" [cdkCopyToClipboard]="startPoint.address.addressFormatted"
                      (cdkCopyToClipboardCopied)="onCopySuccess($event)" class="p-button-sm p-button-sm"></button>
            </div>
          </div>
        </div>
        <!--end address-->
        <div class="grid mb-3">
          <div class="col-fixed col-2">
            <img src="{{DESTINATION_ICON}}" alt="End Address Flag"/>
            <p>End / Last</p>
          </div>
          <div class="col-fixed col-7">
            <div class="p-inputgroup">
              <input pInputText appGooglePlacesAutocomplete (addressSelected)="endAddressChange($event)"
                     formControlName="endAddress" placeholder="Enter an address or place">
              <button pButton icon="pi pi-copy" [cdkCopyToClipboard]="endpoint.address.addressFormatted"
                      (cdkCopyToClipboardCopied)="onCopySuccess($event)" class="p-button-sm p-button-sm"></button>
            </div>
            <div class="col-12 px-0">
              <p-checkbox binary="true" formControlName="roundTrip"
                          label="Set End/Last Address same as Start/First"></p-checkbox>
            </div>
          </div>
          <div class="col-fixed" *ngIf="legs">
            <div>
              + {{ getDuration(legs.length - 1) | duration }} /
              + {{ getDistance(legs.length - 1) | distance : "mi" }}
              <button pButton icon="pi pi-info" *ngIf="legs" (click)="showLeg(legs.length - 1)"
                      class="p-button-sm p-button-info ml-2"></button>
            </div>
          </div>
        </div>
        <hr>
        <!--technician address-->
        <div class="grid mb-3">
          <div class="col-fixed col-2">
            <img src="{{TECH_HOME_ICON}}" alt="End Address Flag"/>
            <p>Tech Address</p>
          </div>
          <div class="col-fixed col-7">
            <div class="p-inputgroup">
              <input pInputText appGooglePlacesAutocomplete (addressSelected)="techHomeAddressChange($event)"
                     formControlName="address" placeholder="Technician address">
              <button pButton icon="pi pi-copy" [cdkCopyToClipboard]="selectedTechnician?.address?.addressFormatted"
                      (cdkCopyToClipboardCopied)="onCopySuccess($event)" class="p-button-sm p-button-sm"></button>
            </div>
          </div>
          <div class="col m-0 p-0">
            <div class="col-12 col-offset-4">
              <p-checkbox binary="true"
                          label="Set as start"
                          formControlName="asStart"
                          pTooltip="Optimize route" (onChange)="setTechAddressAsStart()"></p-checkbox>
            </div>
            <div class="col-12 col-offset-4">
              <p-checkbox binary="true"
                          label="Set as end"
                          formControlName="asEnd"
                          pTooltip="Optimize route" (onChange)="setTechAddressAsEnd()"></p-checkbox>

            </div>
          </div>
        </div>

        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div formArrayName="stops" class="p-toolbar width-100 mt-2 p-2"
               *ngFor="let addressFC of getStopsControls(); let i = index; let last = last;">
            <ng-container [formGroupName]="i">
              <div class="grid width-100">
                <div class="col-fixed">
                  <div class="flex flex-column align-items-center" cdkDrag>
                    <div class="marker-block cursor-move">
                      <img class="marker-img" src="assets/images/red-marker.png" alt=""/>
                      <span class="marker-text"> {{ i + 1 }}</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="grid width-100">
                    <div class="md:col-4 col-12">
                      <div class="p-inputgroup">
                        <input class="text-sm" pInputText type="text" formControlName="customer"
                               placeholder="Customer"/>
                        <button pButton icon="pi pi-copy" [cdkCopyToClipboard]="stops[i].address.addressFormatted"
                                (cdkCopyToClipboardCopied)="onCopySuccess($event)" class="p-button-sm"></button>
                      </div>
                      <div class="text-center">
                        <a [routerLink]="['/tickets', getTicketId(i)]" class="font-bold pt-1 list-link ticket-link"
                           *ngIf="getTicketId(i)"># {{ getTicketId(i) }}</a>
                      </div>
                    </div>
                    <div class="md:col-8 col-12">
                      <div class="p-inputgroup">
                        <button pButton icon="pi pi-external-link" (click)="openMap(i)" class="p-button-sm"></button>
                        <input class="text-sm" pInputText appGooglePlacesAutocomplete
                               (addressSelected)="onAddressChange($event, i)"
                               formControlName="address" placeholder="Enter an address or place">
                        <button pButton icon="pi pi-info" *ngIf="legs" (click)="showLeg(i)"
                                class="p-button-sm p-button-info p-button-sm"></button>
                      </div>
                      <div class="text-center">
                        <ng-container *ngIf="legs && legs[i] && i != legs.length - 1">
                        <span>+ {{ getDistance(i) | distance : "mi" }}
                          / + {{ getDuration(i) | duration }}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="grid width-100">
                    <div class="col-12">
                      <div class="p-inputgroup">
                        <input pInputText value="{{getServiceDateTime(i)}}" disabled>
                        <ng-container *tjHasRole="'TICKET_EDIT'">
                          <button pButton icon="pi pi-pencil"
                                  *ngIf="addressFC.get('ticketId').getRawValue() && route?.id"
                                  (click)="editServiceDates(i)" class="p-button-sm"></button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-fixed">
                  <div class="col-12">
                    <button pButton icon="pi pi-trash" (click)="removeStop(i)"
                            class="p-button-sm p-button-danger p-button-sm"></button>
                  </div>
                  <div class="col-12">
                    <p-checkbox binary="true"
                                label="Set as start"
                                formControlName="asStart"
                                pTooltip="Optimize route" (onChange)="setAsStart($event.checked, i)"></p-checkbox>
                  </div>
                  <div class="col-12">
                    <p-checkbox binary="true"
                                label="Set as end"
                                formControlName="asEnd"
                                pTooltip="Optimize route" (onChange)="setAsEnd($event.checked, i)"></p-checkbox>

                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button pButton (click)="onAddAddress()" class="p-button-danger mr-1" icon="pi pi-plus"></button>
        <button pButton (click)="showRoute()">Show route</button>
      </div>
    </div>
    <div class="col-12 lg:col-6" *ngIf="showMap">
      <p-gmap #gmap [options]="mapDefaultConfig" [overlays]="markers"
              [style]="{ width: '100%', height: '600px' }"></p-gmap>
    </div>
  </div>
</p-panel>

<p-sidebar
  [(visible)]="visibleLegSidebar"
  position="right"
  [baseZIndex]="10000"
  styleClass="p-sidebar-md"
>
  <tj-route-directions
    [legs]="[selectedLeg]"
    *ngIf="selectedLeg"
  ></tj-route-directions>
</p-sidebar>

<p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
