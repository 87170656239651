import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {InvoiceList, UserInvoices} from '../../../../api/model/InvoiceList.model';
import {Router} from '@angular/router';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {ConfirmationService} from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {environment} from '../../../../../environments/environment';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';
import {filter, map} from 'rxjs/operators';
import {EmailDetailsComponent} from '../../../shared/components';
import {download} from '../../../../api/model/shared/functions';

@Component({
  selector: 'tj-partner-invoice-list',
  templateUrl: './partner-invoice-list.component.html',
  styleUrls: ['./partner-invoice-list.component.scss']
})
export class PartnerInvoiceListComponent implements OnInit {

  protected readonly env = environment;

  userInvoices: UserInvoices;

  selectedInvoices: InvoiceList[] = [];

  userId: number;

  type: TicketPartnerStatus;

  startDate: string;

  endDate: string;

  mediaDialogRef: DynamicDialogRef;

  batchActions: DropdownModel[] = [
    {label: 'Select all', value: 'SELECT_ALL'},
    {label: 'Select overdue', value: 'SELECT_OVERDUE'},
    {label: 'Pay', value: 'PAY'},
  ];

  constructor(private api: ApiService,
              public configService: ConfigService,
              private confirmationService: ConfirmationService,
              private dialogConfig: DynamicDialogConfig,
              private notificationService: NotificationService,
              public dialogService: DialogService,
              private router: Router,
              private keycloakServices: TjKeycloakService) {
  }

  ngOnInit() {
    this.userId = this.dialogConfig.data.userId;
    this.type = this.dialogConfig.data.type;
    this.startDate = this.dialogConfig.data.startDate;
    this.endDate = this.dialogConfig.data.endDate;
    if (this.type === TicketPartnerStatus.PRIVATE) {
      this.getCustomerInvoices();
    } else {
      this.getPartnerInvoices();
    }
  }

  setBatchAction(event: any): void {
    switch (event.value) {
      case 'SELECT_ALL':
        this.selectAll();
        break;
      case 'SELECT_OVERDUE':
        this.selectAllOverdue();
        break;
      case 'PAY':
        if (this.selectedInvoices.length == 0) {
          this.notificationService.warning('Please select an invoice');
          return;
        }
        this.pay(this.selectedInvoices.map(it => it.id));
        break;
    }
  }

  private pay(numbers: number[]) {
    const invoices = numbers.join(',');
    const toPaymentPage = () =>
      this.router.navigate(['/payment'], {
        queryParams: {itemType: 'invoice', itemId: invoices},
        queryParamsHandling: 'merge'
      });
    toPaymentPage();
  }

  public selectAll() {
    this.selectedInvoices = [];
    this.userInvoices.invoices.forEach(ticket => this.selectedInvoices.push(ticket));
  }

  public selectAllOverdue() {
    this.selectedInvoices = [];
    this.userInvoices.invoices.filter(it => it.status === 'OVERDUE').forEach(ticket => this.selectedInvoices.push(ticket));
  }

  canEditStatus(): boolean {
    return this.keycloakServices.hasRole('INVOICE_EDIT');
  }

  isPrivatePartner(ticketPartnerStatus): boolean {
    return ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE] ||
      ticketPartnerStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS];
  }

  private getPartnerInvoices() {
    const partnerId = this.userId;
    if (!partnerId) {
      this.notificationService.info('Account was not selected');
      return;
    }
    this.api.invoice.getPartnerInvoices(this.startDate, this.endDate, partnerId)
      .subscribe(invoices => {
        this.userInvoices = invoices;
      });
  }

  private getCustomerInvoices() {
    const partnerId = this.userId;
    if (!partnerId) {
      this.notificationService.info('Customer was not selected');
      return;
    }
    this.api.invoice.getCustomerInvoice(this.startDate, this.endDate, partnerId)
      .subscribe(invoices => {
        this.userInvoices = invoices;
      });
  }

  clear() {
    this.selectedInvoices = [];
  }

  showEmailInputDialog() {
    this.mediaDialogRef = this.dialogService.open(EmailDetailsComponent, {
      header: `Send to email`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        email: 'email',
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined),
      map(data => data.value));
  }

  sendToPartner() {
    const ids = this.selectedInvoices.map(it => it.id);
    this.api.invoice.sendToPartner(ids)
      .subscribe(value => {
        this.notificationService.success('Sent');
      }, error => {
        this.notificationService.error('Unable to sent');
      });
  }

  sendToCustomer() {
    const ids = this.selectedInvoices.map(it => it.id);
    this.api.invoice.sendToCustomer(ids)
      .subscribe(value => {
        this.notificationService.success('Sent');
      }, error => {
        this.notificationService.error('Unable to sent');
      });
  }

  sendToEmail() {
    this.showEmailInputDialog().subscribe(email => {
      const ids = this.selectedInvoices.map(it => it.id);
      this.api.invoice.sendToEmail(email, ids)
        .subscribe(value => {
          this.notificationService.success('Sent');
        }, error => {
          this.notificationService.error('Unable to sent');
        });
    });
  }

  downloadStatement() {
    const ids = this.selectedInvoices.map(it => it.id);
    const timestamp = new Date().getTime();
    const fileName = `Statement_${timestamp}`;
    this.api.invoice.downloadStatement(ids)
      .subscribe(value => {
        download(value, fileName);
      });

  }

  onCopySuccess($event: boolean) {
    if ($event) {
      this.notificationService.copied();
    } else {
      this.notificationService.copyFailed();
    }
  }
}
