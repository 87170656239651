<div [formGroup]="currentFormGroup">

  <p-panel header="Appointment" styleClass="mb-4">
    <div class="flex flex-wrap">
      <ng-container *ngIf="isSetServiceDateTime()">
        <div class="col-12 font-medium justify-content-center mb-1 mt-2 ng-star-inserted text-center">
          <div class="col">No set service date or time</div>
        </div>
      </ng-container>
      <ng-container *ngIf="showAppointmentServiceDate">
        <div class="xl:col-3 lg:col-6 md:col-6 custom-col">
          <tj-field label="Service date" [control]="fcServiceDate">
            <tj-datepicker
              [disabled]="readonly"
              [ngClass]="fcServiceDate.value ? 'selected-item-bold' : ''"
              placeholder="Service date"
              formControlName="serviceDate"
              [onlyFuture]="false"
              (clearDate)="onClearDate()"
              (dateChange)="onServiceDateChange($event)"
            ></tj-datepicker>
          </tj-field>
        </div>
        <div class="xl:col-2 lg:col-6 md:col-6 custom-col">
          <tj-field label="Start time" [control]="fcServiceTimeStart">
            <tj-time
              [readonly]="readonly"
              placeholder="Select"
              formControlName="serviceTimeStart"
              [currentDate]="fcServiceDate.value"
            ></tj-time>
          </tj-field>
        </div>

        <div class=" xl:col-2 lg:col-6 md:col-6 custom-col">
          <tj-field label="End time" [control]="fcServiceTimeEnd">
            <tj-time
              [readonly]="readonly"
              placeholder="Select"
              formControlName="serviceTimeEnd"
              [currentDate]="fcServiceDate.value"
            ></tj-time>
          </tj-field>
        </div>

        <div class="xl:col-2 lg:col-6 md:col-6 custom-col">
          <tj-field label="Time needed" [control]="fcTimeNeeded">
            <p-dropdown
              [disabled]="readonly"
              [optionLabel]="label"
              [options]="ticketEstimations"
              placeholder="Estimate"
              formControlName="timeNeeded"
              (onChange)="changeTimeNeeded($event)"
            >
              <ng-template let-item pTemplate="selectedItem">
              <span class="font-bold"
              >{{ item.label }}
                {{ item.label === 1 ? "hour" : "hours" }}</span
              >
              </ng-template>
              <ng-template let-item pTemplate="item">
              <span
              >{{ item.label }}
                {{ item.label === 1 ? "hour" : "hours" }}</span
              >
              </ng-template>
            </p-dropdown>
          </tj-field>
        </div>
        <div class="xl:col-3 lg:col-6 md:col-6 custom-col notify-customer align-self-end"
             *ngIf="canViewCustomerNotifyButton()">
          <button
            [disabled]="
            !(
              fcServiceDate.dirty ||
              fcServiceTimeStart.dirty ||
              fcServiceTimeEnd.dirty ||
              fcTimeNeeded.dirty
            )
          "
            (click)="saveAndNotify()"
            [loading]="isSaving"
            icon="pi pi-bell"
            label="Notify Customer"
            styleClass="button secondary"
            pButton
          ></button>
        </div>
        <div class="lg:col-offset-3 lg:col col-12"
             [ngClass]="currentFormGroup.invalid && currentFormGroup.hasError('timeRangeError') ? 'display' : 'hidden'">
          <tj-error
            *ngIf="
            currentFormGroup.invalid &&
            currentFormGroup.hasError('timeRangeError')
          "
          >
            Start time should be before the end time
          </tj-error>
        </div>
        <div class="col-12 lg:pt-0">
          <tj-field
            label="Appointment additional details"
            size="4"
            [control]="fcAppointmentDetails"
          >
            <input
              pInputText
              type="text"
              placeholder=""
              formControlName="appointmentDetails"
            />
          </tj-field>
        </div>
        <div class="col-12 flex justify-content-start  align-items-center">
          <ng-container *ngIf="canEditPickUpDelivery()">
            <div class="lg:col-3 sm:col-6 lg:mr-0 pl-0 md:mr-0 mr-3">
              <tj-field
                label="Pick up / Delivery"
                size="4"
                [control]="fcPickUpDelivery"
                class="custom-style-pickUpDelivery"
              >
                <p-checkbox
                  formControlName="pickUpDelivery"
                  name="pickUpDelivery"
                  binary="true"
                ></p-checkbox>
              </tj-field>
            </div>
          </ng-container>
          <ng-container *ngIf="canEditRecallGoingBack()">
            <div class="lg:col-4 pl-0 pr-0 sm:col-6">
              <tj-field
                label="Recall / Going back"
                size="4"
                [control]="fcRecallGoingBack"
                class="custom-style-pickUpDelivery"
              >
                <p-checkbox
                  formControlName="recallGoingBack"
                  name="recallGoingBack"
                  binary="true"
                ></p-checkbox>
              </tj-field>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="fcTechnicians.controls.length > 0 && canViewCheckReceived() && !canEditCheckReceived()">
        <div class="col-12 pb-0">
          <div>Check Received?
            <p-checkbox
              name="checkReceived"
              formControlName="checkReceived"
              binary="true"
            ></p-checkbox>
          </div>
        </div>
      </ng-container>
      <div class="col-12">
        <ng-container
          formArrayName="technicians"
          *ngIf="fcTechnicians.controls.length > 0"
        >
          <div
            class="lg:col-9 col-12 align-self-center"
            *ngIf="!canChangeTechnician()"
          >
            Assigned Technician(s)
          </div>

          <ng-container
            *ngFor="let technicianRow of fcTechnicians.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="grid pt-1 pb-3  border-bottom-1 border-black-alpha-20">
              <div class="lg:col-9 md:col-12 col-12" *ngIf="canChangeTechnician()">
                <tj-field
                  [control]="technicianRow.controls.id"
                  label="Technician"
                >
                  <tj-technician-combobox
                    [forceShowTechnicianId]="technicianRow.controls.id.value"
                    [forceShow]="true"
                    formControlName="id"
                    [showMarker]="true"
                    [areas]="areas"
                    (technicianChange)="technicianChange($event)"
                    [showSpeciality]="true"
                    [showInfo]="true"
                    [disabled]="isSaving || readonly"
                  ></tj-technician-combobox>
                </tj-field>

                <div
                  *ngIf="isTechnicianHourBased(technicianRow.controls.id.value)"
                >
                  <small class="font-semibold"
                  >Technician hourly rate is:
                    {{
                    getTechnicianUnitValue(technicianRow.controls.id.value)
                      | currency : "USD"
                    }}. Use the Bill/Hour to calculate the technician
                    payment.</small
                  >
                </div>
              </div>

              <div
                class="lg:col-9 col-12 align-self-center"
                *ngIf="!canChangeTechnician() && !canViewTechnicianName()"
              >
                  <span class="col-12 font-bold">
                    {{ ticket.technicians[i].personnelCode }}
                  </span>
              </div>
              <div
                class="lg:col-9 col-12 align-self-center mt-3"
                *ngIf="canViewTechnicianName()"
              >
                  <span class="col-12 font-bold">
                    {{ ticket.technicians[i].name }}
                  </span>
              </div>
              <ng-container *ngIf="canViewTechnicianWorkPrice()">
                <div class="align-items-center flex flex-grow-1 justify-content-between lg:w-auto w-full">
                  <div class="text-center">
                    <div>Primary</div>
                    <div class="mt-2">
                      <p-checkbox
                        [disabled]="isSaving || readonly"
                        id="{{ 'master' + i }}"
                        name="master"
                        formControlName="head"
                        (onChange)="onTechnicianHeadChange(i)"
                        binary="true"
                      ></p-checkbox>
                    </div>
                  </div>
                  <div class="text-center">
                    <div>
                      <div>Parts</div>
                      <div class="mt-2">
                        <p-checkbox
                          [disabled]="isSaving || readonly"
                          formControlName="partsPickup"
                          name="partsPickup"
                          binary="true"
                          (onChange)="onTechnicianPartsPickupChange(i)"
                        ></p-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3">
                    <ng-container *ngIf="!isPayFormed">
                      <button
                        *tjHasRole="'TICKET_EDIT_TECHNICIAN'"
                        [disabled]="isSaving || readonly"
                        (click)="onRemoveTechnician(i)"
                        class="p-button-danger"
                        icon="pi pi-trash"
                        pButton
                      ></button>
                    </ng-container>
                  </div>
                </div>

                <div class="md:col-12 col-12">
                  <div class="mt-1 " *ngIf="techLatestRouteMap.has(technicianRow.controls.id.getRawValue())">
                    <i class="pi pi-map text-green-700"></i>
                    <span> Route is available </span>
                    <a [routerLink]="['/routes', techLatestRouteMap.get(technicianRow.controls.id.getRawValue()).routeId]"
                       class="list-link"># {{techLatestRouteMap.get(technicianRow.controls.id.getRawValue()).routeId}}</a>
                    <span> stop #{{ techLatestRouteMap.get(technicianRow.controls.id.getRawValue()).position }} </span>
                  </div>

                  <div class="mt-1 " *ngIf="techRoutesByDateMap.has(technicianRow.controls.id.getRawValue())">
                    <div
                      *ngFor="let techRouteModel of techRoutesByDateMap.get(technicianRow.controls.id.getRawValue()).routesByDate">

                      <div class="mb-2" *ngIf="!techRouteModel.matchByArea">
                        <i class="pi pi-map text-green-700"></i>
                        <span> Technician have a route </span>
                        <a [routerLink]="['/routes', techRouteModel.id]"
                           class="list-link"> #{{ techRouteModel.id }} </a>
                        <span> for </span>
                        <b> this date </b>
                        <span> and </span>
                        <b> this area </b>
<!--                        <a [routerLink]="['/routes', techRouteModel.id]" class="list-link"> (add this ticket to the-->
<!--                          route) </a>-->
                      </div>

                      <div class="mb-2" *ngIf="techRouteModel.matchByArea">
                        <i class="pi pi-map text-green-700"></i>
                        <span> Technician have a route</span>
                        <a [routerLink]="['/routes', techRouteModel.id]" class="list-link"> #{{ techRouteModel.id }} </a>
                        <span> for </span>
                        <b>this date</b>
                        <span> in different area: </span>
                        <b> {{ techRouteModel.matchByArea }} </b>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="md:col-3 col-6">
                  <tj-field
                    [control]="technicianRow.controls.incomeCash"
                    label="Cash"
                  >
                    <tj-money-input formControlName="incomeCash"></tj-money-input>
                  </tj-field>
                </div>

                <div class="md:col-3 col-6">
                  <tj-field
                    [control]="technicianRow.controls.check"
                    label="Check"
                  >
                    <tj-money-input formControlName="check"></tj-money-input>
                  </tj-field>
                </div>

                <div class="md:col col-6">
                  <tj-field
                    [control]="technicianRow.controls.bill"
                    label="Bill/Hour"
                  >
                    <tj-money-input formControlName="bill"></tj-money-input>
                  </tj-field>
                </div>

                <div class="md:col-3 col-6">
                  <tj-field
                    [control]="technicianRow.controls.creditCard"
                    label="Credit card"
                  >
                    <tj-money-input formControlName="creditCard"></tj-money-input>
                  </tj-field>
                </div>

                <div
                  class="col-12"
                  *ngIf="technicianRow.controls.partsPickup.value === true"
                >
                  <div class="grid">
                    <div class="lg:col-4 col-12">
                      <tj-field
                        label="Company expenses"
                        [control]="technicianRow.controls.expenses"
                      >
                        <tj-money-input
                          formControlName="expenses"
                        ></tj-money-input>
                      </tj-field>
                    </div>

                    <div class="lg:col-8 col-12">
                      <tj-field
                        label="Company expenses description"
                        [control]="technicianRow.controls.expensesDescription"
                      >
                        <input
                          pInputText
                          type="text"
                          class="tj-jobs-checkbox"
                          formControlName="expensesDescription"
                        />
                      </tj-field>
                    </div>

                    <div class="lg:col-4 col-12">
                      <tj-field
                        label="Technician expenses"
                        [control]="technicianRow.controls.technicianExpenses"
                      >
                        <tj-money-input
                          formControlName="technicianExpenses"
                        ></tj-money-input>
                      </tj-field>
                    </div>

                    <div class="lg:col-8 col-12">
                      <tj-field
                        [control]="
                        technicianRow.controls.technicianExpensesDescription
                      "
                        label="Technician expenses description"
                      >
                        <input
                          pInputText
                          type="text"
                          class="tj-jobs-checkbox"
                          formControlName="technicianExpensesDescription"
                        />
                      </tj-field>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="ticket.technicians[i]?.payFormId">
                  <a
                    class="underline"
                    [queryParams]="{ ticketId: ticket.id }"
                    [routerLink]="['/payforms', ticket.technicians[i].payFormId]"
                  >
                    Pay form #{{ ticket.technicians[i].payFormId }} created on
                    {{
                    ticket.technicians[i].payFormCreatedAt
                      | date : configService.dateFullFormat
                    }}
                  </a>
                  <button *ngIf="shouldShowButton('edit', ticket.technicians[i].id) && canChangeTechnician()"
                          [disabled]="isSaving || readonly"
                          pButton type="button" class="ml-3 cursor-pointer"
                          (click)="editPayForm(ticket.technicians[i].id)">Edit Pay Form
                  </button>
                  <button
                    *ngIf="shouldShowButton('update', ticket.technicians[i].id) && canChangeTechnician()"
                    [disabled]="isSaving || readonly"
                    (click)="onUpdatePayForm(ticket.technicians[i].id)"
                    pButton type="button"
                    class="ml-3 cursor-pointer">Update Pay Form
                  </button>
                  <button
                    *ngIf="shouldShowButton('cancel', ticket.technicians[i].id) && canChangeTechnician()"
                    [disabled]="isSaving || readonly"
                    pButton type="button" class="ml-3 cursor-pointer"
                    (click)="onCancelPayFormChanges(ticket.technicians[i].id)">Cancel
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="fcTechnicians.controls.length === 0">
          <div
            class="font-medium grid justify-content-center mb-1 mt-2 ng-star-inserted text-center"
          >
            <div class="col"
                 [ngClass]="{'text-red-600 text-2xl font-semibold': fcServiceDate.value}">No
              technicians assigned
            </div>
          </div>
        </ng-container>
      </div>

      <div class="col-12 flex">
        <div
          *ngIf="fcTechnicians.invalid && fcTechnicians.hasError('uniqueness')"
        >
          <tj-error>You can't assign same technician twice.</tj-error>
        </div>
        <div
          class="flex flex-1 justify-content-between"
          *tjHasRole="'TICKET_EDIT_TECHNICIAN'"
        >
          <div *tjHasRole="'TICKET_CREATE_PAYMENTS'">
            <button
              *ngIf="
                ticket?.status == 'COMPLETED' &&
                ticket.serviceDate &&
                ticket?.technicians.length > 0 && !isPayFormed
              "
              class="p-button-success"
              label="Send to pay form"
              type="button"
              [disabled]="sendToPayFormClicked || isSaving || readonly"
              pButton
              (click)="onSendToPayForm()"
            ></button>

          </div>
          <ng-container *ngIf="!isPayFormed">
            <button
              class="p-button-secondary p-button-raised"
              icon="pi pi-plus"
              label="Add technician"
              pButton
              [disabled]="isSaving || readonly"
              (click)="onAddTechnician()"
            ></button>
          </ng-container>
        </div>
      </div>
    </div>
  </p-panel>

  <p-panel header="Issue/Order" styleClass="mb-4">
    <div class="flex flex-wrap">
      <div class="col-12">
          <tj-field label="Issue" [control]="fcIssue">
            <textarea
              style="z-index: 10"
              formControlName="issue"
              id="issue"
              pInputTextarea=""
              placeholder="Issue *"
              rows="10"
            ></textarea>
          </tj-field>
      </div>
      <ng-container *ngIf="configService.isEnabledWarranty()">
        <div class="lg-4 col-6">
          <tj-field label="Warranty start date" [control]="fcDeliveryDate">
            <tj-datepicker [onlyFuture]="false" formControlName="deliveryDate"
                           [ngClass]="fcDeliveryDate.value ? 'selected-item-bold' : ''"
                           placeholder="Warranty start date"></tj-datepicker>
          </tj-field>
        </div>
        <div class="lg-4 col-6">
          <tj-field label="Warranty terms" [control]="fcWarrantyTerms">
            <input pInputText type="text" placeholder="Warranty terms" id="warrantyTerms"
                   formControlName="warrantyTerms"/>
          </tj-field>
        </div>
      </ng-container>
      <div class="col-12">
        <tj-field label="PO/WO/SALES" [control]="fcExternalReferenceCode">
          <input pInputText type="text" placeholder="PO/WO/SALES" id="externalReferenceCode"
                 formControlName="externalReferenceCode"/>
        </tj-field>
      </div>
      <div class="col-12">
        <tj-field
          label="Type of Service/Lead/Note (shown on home page)"
          [control]="fcCategory"
        >
          <input
            formControlName="category"
            id="category"
            pInputText
            placeholder="Type of Service"
          />
        </tj-field>
      </div>

      <div class="col-12" *tjHasRole="'TICKET_VIEW_INTERNAL'">
        <tj-field
          label="Internal Info (Confidential)"
          [control]="fcInternalInfo"
        >
          <textarea
            pInputTextarea
            placeholder="Internal Info"
            id="internalInfo"
            class="tj-internal-info-textarea"
            formControlName="internalInfo"
            style="z-index: 10"
          ></textarea>
        </tj-field>
      </div>

      <div class="col-12">
        <tj-field label="Additional Notes" [control]="fcAdditionalNotes">
          <textarea
            pInputTextarea
            placeholder="Additional Notes"
            id="additionalNotes"
            formControlName="additionalNotes"
            style="z-index: 10"
          ></textarea>
        </tj-field>
      </div>
      <div class="col-12">
        <tj-field label="Report" [control]="fcReport">
          <textarea
            style="z-index: 10"
            formControlName="report"
            id="report"
            pInputTextarea
            placeholder="Report"
            rows="10"
          ></textarea>
        </tj-field>
      </div>
    </div>
  </p-panel>
</div>
<p-confirmDialog [closable]="false" appendTo="body" key="confirmAddTechnician"></p-confirmDialog>
<p-confirmDialog [closable]="false" appendTo="body" key="confirmLastDay"></p-confirmDialog>

