import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequestService} from './service/request.service';
import {CommunicationService} from './service/communication.service';
import {ApiService} from './service/api.service';
import {
  AreaApi,
  ChatApi,
  CommentApi,
  GalleryApi,
  InvoiceApi,
  PartnerApi,
  PayFormApi,
  PaymentApi,
  SignatureApi,
  TagApi,
  TechnicianApi,
  TicketApi,
  UserApi,
  SchedulerApi,
  ZipCodeApi
} from './endpoints';
import {HttpClientModule} from '@angular/common/http';
import {CommunicationFilterableService} from './service/communication-filtarable.service';
import {GlobalConfigApi} from './endpoints/global-config.api';
import {RouteApi} from './endpoints/route.api';
import {GeocodingApi} from './endpoints/geocoding.api';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    InvoiceApi,
    PartnerApi,
    PayFormApi,
    GalleryApi,
    SignatureApi,
    TechnicianApi,
    TicketApi,
    GeocodingApi,
    UserApi,
    PaymentApi,
    CommentApi,
    ChatApi,
    SchedulerApi,
    ZipCodeApi,
    AreaApi,
    GlobalConfigApi,
    TagApi,
    RequestService,
    CommunicationService,
    CommunicationFilterableService,
    RouteApi,
    ApiService
  ]
})
export class ApiModule {
}
