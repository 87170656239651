import {Injectable} from '@angular/core';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {SearchQuery} from '../model/shared/Page.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Coordinates} from '../model/geocoding/coordinates';

@Injectable()
export class GeocodingApi extends CommunicationFilterableService implements SearchQuery {

  protected apiEndpoint = this.env.apiEndpoint + 'geocoding';

  getCoordinates(address: string): Observable<Coordinates> {
    const url = `${this.apiEndpoint}?address=${address}`;
    return this.requestService
      .get(url, {})
      .pipe(
        map(response => response.response as Coordinates),
        catchError(this.handleError));
  }
}
