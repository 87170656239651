import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ApiService} from '../../../../api/service/api.service';
import {AttachmentListResponseModel} from '../../models/Gallery.model';
import {ImageRequestModel} from '../../models/ImageRequest.model';
import {GalleryService} from '../../services';

@Component({
  selector: 'tj-gallery-preview',
  templateUrl: './gallery-preview.component.html',
  styleUrls: ['./gallery-preview.component.scss']
})
export class GalleryPreviewComponent implements OnInit {

  galleryId: number = null;
  ticketId: number = null;

  images: ImageRequestModel[] = [];
  displayedImages: ImageRequestModel[] = [];

  // Pagination properties
  totalRecords = 0;
  rows = 5; // Number of images per page
  page = 1;  // Current page

  constructor(public config: DynamicDialogConfig, private apiService: ApiService,
              private galleryService: GalleryService) {
  }

  ngOnInit(): void {
    this.galleryId = this.config.data?.id;
    this.ticketId = this.config.data?.ticketId;

    if (this.galleryId && this.galleryId > 0) {
      this.apiService.gallery.getImages(this.galleryId, this.ticketId)
        .subscribe((responseModel: AttachmentListResponseModel<ImageRequestModel>) => {
          this.images = responseModel.attachments;
          this.totalRecords = this.images.length;
          this.updateDisplayedImages();
        });
    }
  }

  public getImagePath(imagePath: string, imageName, index): string {
    const imageIndex = index + 1;
    return this.galleryService.getImageUrl(imagePath, {width: 640, quality: 60}, imageName, index);
  }

  onPageChange(event: any): void {
    this.page = event.page + 1;
    this.rows = event.rows;
    this.updateDisplayedImages();
  }

  updateDisplayedImages(): void {
    const startIndex = (this.page - 1) * this.rows;
    const endIndex = startIndex + this.rows;
    this.displayedImages = this.images.slice(startIndex, endIndex);
  }
}
