import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';
import { AddressDetails } from '../../../api/model/Address.model';

declare var google: any;

@Directive({
  selector: '[appGooglePlacesAutocomplete]'
})
export class GooglePlacesAutocompleteDirective implements OnInit {
  @Output() addressSelected = new EventEmitter<{ address: AddressDetails, valid: boolean }>();

  autocomplete: any;
  autocompleteService: any;
  placesService: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.initAutocomplete();
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(this.el.nativeElement);
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
      types: ['address'], // Restrict the search to geographical location types
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (place.geometry) {
        const addressDetails = this.formatAddressDetails(place);
        this.addressSelected.emit({ address: addressDetails, valid: true });
        this.setValidState(true);
      } else {
        this.addressSelected.emit({ address: null, valid: false });
        this.setValidState(false);
      }
    });
  }

  @HostListener('focusout')
  onFocusOut() {
    const inputValue = this.el.nativeElement.value;

    if (!inputValue) {
      this.setValidState(false);
      this.addressSelected.emit({ address: null, valid: false });
      return;
    }

    // Fetch predictions for the current input value
    this.autocompleteService.getPlacePredictions(
      { input: inputValue, types: ['address'] },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions.length > 0) {
          const placeId = predictions[0].place_id;
          this.placesService.getDetails({ placeId }, (place, detailsStatus) => {
            if (detailsStatus === google.maps.places.PlacesServiceStatus.OK && place.geometry) {
              const addressDetails = this.formatAddressDetails(place);
              this.addressSelected.emit({ address: addressDetails, valid: true });
              this.setValidState(true);
              this.el.nativeElement.value = place.formatted_address;
            } else {
              this.setValidState(false);
              this.addressSelected.emit({ address: null, valid: false });
            }
          });
        } else {
          this.setValidState(false);
          this.addressSelected.emit({ address: null, valid: false });
        }
      }
    );
  }

  // Helper function to extract relevant address information
  formatAddressDetails(place: any): AddressDetails {
    const addressDetails = new AddressDetails();
    addressDetails.addressFormatted = place.formatted_address;
    addressDetails.lat = place.geometry.location.lat();
    addressDetails.lng = place.geometry.location.lng();

    // Extract more address components (country, city, postal code, etc.)
    place.address_components.forEach(component => {
      const types = component.types;
      if (types.includes('country')) {
        addressDetails.country = component.long_name;
      } else if (types.includes('locality')) {
        addressDetails.city = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressDetails.state = component.short_name;
      } else if (types.includes('route')) {
        addressDetails.street = component.long_name;
      } else if (types.includes('street_number')) {
        addressDetails.bld = component.long_name;
      } else if (types.includes('postal_code')) {
        addressDetails.postalCode = component.long_name;
      }
    });

    return addressDetails;
  }

  private setValidState(isValid: boolean): void {
    if (isValid) {
      this.renderer.removeClass(this.el.nativeElement, 'invalid-address');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'invalid-address');
    }
  }
}
